<template>

    <!-- Main content -->
 <div class="content">


 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">GiftCard</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">

                 <button type="button" @click="abrirModal('giftcard','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="codigo">Codigo</option>
                         <option value="monto">Monto</option>
                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="buscar" @keyup="listargiftcard(1,buscar,criterio)">
                       <button type="submit" class="btn btn-primary" @click="listargiftcard(1,buscar,criterio)"><i class="fas fa-search"></i>&nbsp;Buscar</button>
                     </div>
                   </div>
                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Opciones</th>
                    <th>Codigo</th>
                    <th>Monto</th>
                    <th>Fecha_Creacion</th>
                    <th>Fecha_Fin</th>
                    <th>Estado</th>


                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(giftcard,index) in arraygiftcard" :key="giftcard.id" >
                    <td>{{(index + 1)}}</td>
                    <td>
                       <button type="button" @click="abrirModal('giftcard','actualizar',giftcard)" class="btn btn-warning btn-sm"><i class="fas fa-edit"></i></button>&nbsp;
                      <template v-if="giftcard.estado==1">
                        <button type="button" class="btn btn-danger btn-sm" @click="desactivargiftcard(giftcard.id)">
                          <i class="fas fa-trash-alt"></i>
                          </button>
                      </template>
                      <template v-if="giftcard.estado==3">
                        <button type="button" class="btn btn-info btn-sm" @click="activargiftcard(giftcard.id)"><i class="far fa-thumbs-up"></i></button>
                      </template>

                    </td>


                                    <td v-text="giftcard.codigo"></td>
                                    <td v-text="giftcard.monto"></td>
                                    <td v-text="giftcard.fecha_star"></td>
                                    <td v-text="giftcard.fecha_end"></td>


                   <td>
                                      <div v-if="giftcard.estado==1">
                                           <span class="badge badge-success"> Activo </span>
                                       </div>
                                       <div v-if="giftcard.estado==2">
                                           <span class="badge badge-danger"> Cangeado </span>
                                       </div>
                                       <div v-if="giftcard.estado==3">
                                           <span class="badge badge-warning"> Caducado </span>
                                       </div>


                   </td>

                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>




          <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModal"></h4>
              <button type="button" class="close" @click="cerrarModal()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                 <div class="container-fluid">
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">





                    <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Codigo:</label>
                     <input type="text" class="form-control" @blur="duplicado()"  v-model="codigo">
                  </div>

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Monto:</label>
                     <input type="text" class="form-control"   v-model="monto">
                  </div>


                   <div class="form-group row">
                     <label for="message-text" class="col-form-label">Fecha_End:</label>
                      <input type="date" class="form-control"  v-model="fecha_end" >
                  </div>




                   <div v-show="errorgiftcard" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjgiftcard" :key="error" v-text="error">
                                        </div>
                                    </div>
                  </div>
                  <div v-show="deshabilitar_boton" class="form-growp row  div-error">
                    <p class="text-center text-error">El gift ya Existe!</p>
                  </div>
                  </form>
                 </div>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click="cerrarModal()">Close</button>
              <button type="button" v-if="tipoAccion==1" :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrargiftcard()">Guardar</button>
               <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click="actualizargiftcard()">Actualizar</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->






      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>
<script>




export default {

       data (){
            return {
                giftcard_id: 0,

                codigo : '',
                monto : '',
                fecha_end :'',
                lugar : '',
                arraygiftcard : [],


                modal : 0,
                tituloModal : '',
                tipoAccion : 0,
                errorgiftcard : 0,
                errorMostrarMsjgiftcard : [],
                 'mensaje':'',
                 deshabilitar_boton:0,
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'codigo',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            }
        },
        methods : {
            listargiftcard (page,buscar,criterio){

                let me=this;
                 var url= '/giftcard?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
                axios.get(url).then(function (response) {
                 console.log(response);
                    var respuesta= response.data;
                    me.arraygiftcard = respuesta.gifts.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },



            cambiarPagina(page,buscar,criterio){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listargiftcard(page,buscar,criterio);
            },



            registrargiftcard(){
                if (this.validargiftcard()){
                    return;
                }

                let me = this;

                axios.post('/giftcard/registrar',{



                    'codigo': this.codigo,
                    'monto': this.monto,
                    'fecha_end': this.fecha_end


                }).then(function (response) {
                  //console.log(response);
                    me.cerrarModal();
                    me.deshabilitar_boton=0;
                    me.listargiftcard(1,'','nombre');
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });
            },


            actualizargiftcard(){

               if (this.validargiftcard()){
                    return;
                }

                let me = this;



                axios.put('/giftcard/actualizar',{


                    'codigo': this.codigo,
                    'monto': this.monto,
                    'fecha_end': this.fecha_end,

                    'id': this.gift_id


                }).then(function (response) {
                    console.log(response);
                    me.cerrarModal();
                    me.listargiftcard(1,'','nombre');
                        Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                }).catch(function (error) {
                    console.log(error);
                });

            },


           duplicado(){
             //console.log(this.nombre);
               axios.put('/giftcard/validar',{



                'codigo': this.codigo,

                }).then(response => {
                  this.mensaje = response.data;
                  //console.log(this.mensaje);
                  if(this.mensaje==="Existe"){
                      this.deshabilitar_boton=1;

                  }else{
                    this.deshabilitar_boton=0;
                  }
                })
                .catch(error => {
                   console.log(err);
                 });

            },



             validargiftcard(){


               this.errorgiftcard=0;
                this.errorMostrarMsjgiftcard  =[];





                if (!this.codigo){ this.errorMostrarMsjgiftcard.push("El codigo de la GiftCard no puede estar vacío."); this.val1=2; }
                if (!this.monto){ this.errorMostrarMsjgiftcard.push("El Monto de la GiftCard no puede estar vacío."); this.val1=1; }
                if (!this.fecha_end){ this.errorMostrarMsjgiftcard.push("La Fecha limite no puede estar vacío."); this.val4=1; }








                if (this.errorMostrarMsjgiftcard.length) this.errorgiftcard = 1;

                return this.errorgiftcard;

            },
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';

                this.codigo = '';
                this.nomto = '';
                this.fecha_end = '';

                this.errorgiftcard=0;
                this.val1=0;
                this.val2=0;

            },


            abrirModal(modelo, accion, data = []){



switch(modelo){
    case "giftcard":
    {
        switch(accion){
            case 'registrar':

            {
               this.modal = 1;
                this.tituloModal = 'Registrar gift';



                this.codigo = '';
                this.monto = '';
                this.lugar = '';
                this.fecha_end = '';

                this.tipoAccion = 1;
                break;
            }
            case 'actualizar':
            {
                //console.log(data);
                this.modal=1;
                this.tipoAccion=2;
                this.tituloModal='Actualizar gift';
                this.gift_id=data['id'];

                this.codigo = data['codigo'];
                this.monto = data['monto'];
                this.fecha_end=data['fecha_end'];




                break;
            }


        }
    }
}





},





           desactivargiftcard : function(id){
              Swal.fire({
                title: 'Esta seguro/a que desea desactivar el giftcard?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/giftcard/desactivar',{
                          'id': id
                        }).then(function(response){
                          me.listargiftcard(1,'','nombre');
                          Swal.fire(
                            'Desactivado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })
                },


               activargiftcard: function(id){

                Swal.fire({
                title: 'Esta seguro/a que desea activar giftcard?',

                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.value) {
                  let me = this;
                        axios.put('/giftcard/activar',{
                          'id': id
                        }).then(function(response){
                         me.listargiftcard(1,'','nombre');
                          Swal.fire(
                            'activado!',
                            'El registro a sido activado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });


                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
              })


              }


        },
        mounted() {
            this.listargiftcard(1,this.buscar,this.criterio);
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}


</style>
