<template>
    <main class="main">
    <!-- Content Header (Page header) -->
    <div class="content-header">
    <div class="container-fluid">
        <div class="mb-2 row">
        <div class="col-sm-6">

            <h1 class="m-0 text-dark">Guias</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">

        </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    </div>
    <div class="container-fluid">
        <!-- Ejemplo de tabla Listado -->
        <div class="card">



            <!------------------------------------------------- Listado Principal ------------------------------>
            <template v-if="listado==1">
            <div class="card-body">
                    <div class="form-group row">
           <div class="col-md-12">
             <div class="form-inline">

               <label for="FormControlSelect1">Tienda: </label>
               <select class="form-control" id="FormControlSelect1" v-model="lugar">
                <option value="0" disabled>Seleccione</option>
                <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
               </select>







                <label for="FormControlinput1">De: </label>
               <input id="FormControlinput1" type="date" class="form-control" v-model="de" >&nbsp;



                <label for="FormControlinput2">A: </label>
               <input id="FormControlinput2" type="date" class="form-control" v-model="a" >&nbsp;

                <button type="submit" class="btn btn-primary" @click.prevent="listarReporte(lugar,de,a)"><i class="fa fa-search"></i>&nbsp;Buscar</button>
             </div>&nbsp;
             <div>
                     <h5>Total Utilidad: Q {{calcularTotalP}}</h5>
                  <h5>Total Guia Neto: Q {{calcularTotalvP}}</h5>
                        <h6>Cantidad:  {{contarTracking}}</h6>
             </div>



           </div>
                    </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Opciones</th>
                                <th>Usuario</th>
                                <th>Cliente</th>
                              
                                <th>Tipo Comprobante</th>
                        
                                <th>Número Comprobante</th>
                                <th>Fecha Hora</th>
                                <th>Envio</th>
                                 <th>Total Guia</th>
                                <th>Total_neto</th>

                                <th>TotalCosto</th>
                                <th>Utilidad</th>

                                <th>Estado</th>


                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tracking,index) in arrayReporte" :key="tracking.id">
                                <td>
                                            {{(index+1)}}
                                </td>
                                <td>
                                    <button type="button" @click.prevent="verTracking(tracking.id)" class="btn btn-success btn-sm">
                                    <i class="fas fa-eye"></i>
                                    </button> &nbsp;


                                </td>
                                <td v-text="tracking.usuario"></td>
                                        <td v-text="tracking.nombre"></td>
                                        <td v-text="tracking.tipo_comprobante"></td>
                                        
                                        <td v-text="tracking.num_comprobante"></td>
                                        <td v-text="tracking.fecha_hora"></td>
                                        <td v-text="tracking.envio"></td>
                                        <td v-text="tracking.total"></td>
                                        <td v-text="tracking.total_neto"></td>
                                        
                                       
                                        <td v-text="tracking.totalc"></td>
                                        <td v-text="tracking.totalu"></td>


                                <td>

                                <div v-if="tracking.estado=='Registrado'">
                                   <span class="badge badge-success"> Registradas </span>
                                </div>


                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul class="pagination">
                        <li class="page-item" v-if="pagination.current_page > 1">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                        </li>
                    </ul>
                </nav>
            </div>
            </template>
            <!--Fin Listado-->

        <!------------------------------------------------- Detalle ------------------------------>
            <template v-else-if="listado==0">
            <div class="card-body">
                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                            <div class="form-inline">

                                <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                <input type="text" readonly class="form-control" v-model="nit">
                                <input type="text" readonly class="form-control" v-model="nombrep">
                                <input type="text" readonly class="form-control" v-model="direccion">
                                <input type="text" readonly class="form-control" v-model="telefono">
                            </div>
                      </div>
                    </div>

                    <div class="col-md-4">

                        <div class="form-group">
                            <label>Tipo Comprobante(*)</label>
                            <select class="form-control" v-model="tipo_comprobante">
                                <option value="0">Seleccione</option>
                                <option value="COD">COD</option>
                                
                                <option value="COLLET">COLLET</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante(*)</label>
                            <input type="text" class="form-control" v-model="num_comprobante" placeholder="000xx">
                        </div>
                    </div>
                     <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Envio(*)</label>
                            <input type="number" class="form-control" v-model="envio">
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div v-show="errorTracking" class="form-group row div-error">
                            <div class="text-center text-error">
                                <div v-for="error in errorMostrarMsjTracking" :key="error" v-text="error">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                            <div class="form-inline">
                                <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="border form-group row">
                    <div class="table-responsive col-md-12">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Opciones</th>
                                  
                                    <th>Producto</th>
                                    <th id="costo">Costo</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    <th id="totalc">SubtotalCosto</th>
                                    <th id="totalu">SubtotalUtilidad</th>
                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>
                                        <button @click.prevent="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                            <i class="fas fa-times-circle"></i>
                                        </button>
                                    </td>
                                    <td v-text="detalle.producto">
                                    </td>
                                    <td>
                                        <input v-model="detalle.precio" type="number" value="3" class="form-control">
                                    </td>
                                    <td>
                                        <input v-model="detalle.cantidad" type="number" value="2" class="form-control">
                                    </td>
                                    <td>
                                        <span style="color:red;" v-show="detalle.descuento>(detalle.precio*detalle.cantidad)">Descuento superior</span>
                                        <input v-model="detalle.descuento" type="number" class="form-control">
                                    </td>
                                    <td>
                                        {{detalle.precio*detalle.cantidad-detalle.descuento}}
                                    </td>
                                </tr>

                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Parcial:</strong></td>
                                    <td>Q {{totalParcial=(calcularTotal).toFixed(2)}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Envio:</strong></td>
                                    <td>Q {{totalEnvio=envio}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Guia:</strong></td>
                                    <td>Q {{total=( (calcularTotal)+(totalEnvio))}}</td>
                                </tr>
                                  <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Neto:</strong></td>
                                     <td>Q total</td>
                                </tr>

                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <button type="button" @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click.prevent="registrarTracking()">Registrar Tracking</button>
                    </div>
                </div>
            </div>
            </template>
            <!-- Fin Detalle-->

            <!------------------------------------------------- Ver Tracking ------------------------------>
            <template v-else-if="listado==2">
            <div class="card-body">
                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="">Cliente</label>
                            <p v-text="cliente"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo Comprobante</label>
                            <p v-text="tipo_comprobante"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante</label>
                            <p v-text="num_comprobante"></p>
                        </div>
                    </div>
                      <div class="col-md-4">
                        <div class="form-group">
                            <label>Envio</label>
                            <p v-text="envio"></p>
                        </div>
                    </div>

                </div>
                <div class="border form-group row">
                    <div class="table-responsive col-md-12">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Codigo</th>
                                    <th>Marca</th>
                                    <th>Genero</th>
                                    <th>Diseño</th>
                                    <th>Tela</th>
                                    <th>Color</th>
                                    <th>Talla</th>
                                    <th>Lote</th>
                                    <th>Producto</th>
                                    <th>Costo</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    <th>SubtotalCosto</th>
                                    <th>SubtotalUlitidad</th>
                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="detalle in arrayDetalle" :key="detalle.id">
                                    <td v-text="detalle.codigo">
                                            </td>
                                           <td v-text="detalle.marca">
                                            </td>
                                           <td v-text="detalle.genero">
                                            </td>
                                           <td v-text="detalle.diseno">
                                            </td>
                                           <td v-text="detalle.tela">
                                            </td>
                                           <td v-text="detalle.color">
                                            </td>
                                           <td v-text="detalle.talla">
                                            </td>
                                           <td v-text="detalle.lote">
                                            </td>
                                    <td v-text="detalle.articulo">
                                    </td>
                                     <td v-text="detalle.costo">
                                    </td>
                                    <td v-text="detalle.precio">
                                    </td>
                                    <td v-text="detalle.cantidad">
                                    </td>
                                    <td v-text="detalle.descuento">
                                    </td>
                                    <td>
                                        {{detalle.precio*detalle.cantidad}}
                                    </td>
                                    <td>
                                        {{detalle.costo*detalle.cantidad}}
                                    </td>
                                    <td>
                                        {{(detalle.precionormal*detalle.cantidad-detalle.descuento)-(detalle.costo*detalle.cantidad)}}
                                    </td>
                                </tr>

                                 <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Guia:</strong></td>
                                    <td >Q <span id="totalTrackingArticulo">{{total=(parseFloat(totalParcial)+parseFloat(totalEnvio)).toFixed(2)}}</span> </td>
                                </tr>
                                <!--
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                    <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                </tr>
                            -->
                            <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Envio:</strong></td>
                                    <td>Q {{totalEnvio=envio}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>Total Neto:</strong></td>
                                    
                                    <td>Q {{totalParcial=calcularTotal}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>TotalCosto:</strong></td>
                                    <td>Q {{totalc=calcularTotalc}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="5" align="right"><strong>TotalUtilidad:</strong></td>
                                    <td>Q {{totalu=calcularTotalu}}</td>
                                </tr>

                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <button type="button" @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                    </div>
                </div>
            </div>
            </template>
            <!-- fin ver tracking -->
        </div>
        <!-- Fin ejemplo de tabla Listado -->
    </div>




 
</main>
</template>

<script>

export default {
data (){
    return {
        tracking_id: 0,
        idcliente:0,
        idlugar:0,
        idlugar2:1,
        nit: '',
        lugar:'',
          de:"",
        a:"",
        cliente:'',
        nombre : '',
        nombrep : '',
        direccion : '',
        telefono : '',
        urlhttp: this.$api_url,
        tipo_comprobante : 'COD',
        serie_comprobante : '',
        num_comprobante : '',
        envio: 30,
        total:0.0,
        totalEnvio: 0.0,
        totalParcial: 0.0,
        arrayTracking : [],
        arrayReporte: [],
        arrayCliente: [],
        arrayDetalle : [],
        arrayLugar : [],
         arrayLugar2 : [],
        listado:1,
        modal : 0,
        modalB : 0,
        tituloModal : '',
        tituloModalB : '',
        tipoAccion : 0,
        errorTracking : 0,
        errorMostrarMsjTracking : [],
        pagination : {
            'total' : 0,
            'current_page' : 0,
            'per_page' : 0,
            'last_page' : 0,
            'from' : 0,
            'to' : 0,
        },
        offset : 3,
        criterio : 'num_comprobante',
        buscar : '',
        criterioA:'nombre',
        buscarA: '',
        criterioB:'nombre',
        buscarB: '',
        arrayProducto: [],
        idproducto: 0,
       // codigo: '',
        producto: '',
        precio: 0,
        cantidad:0,
        descuento: 0,
        stock: 0
    }
},
components: {

},
computed:{
    isActived: function(){
        return this.pagination.current_page;
    },
    //Calcula los elementos de la paginación
    pagesNumber: function() {
        if(!this.pagination.to) {
            return [];
        }

        var from = this.pagination.current_page - this.offset;
        if(from < 1) {
            from = 1;
        }

        var to = from + (this.offset * 2);
        if(to >= this.pagination.last_page){
            to = this.pagination.last_page;
        }

        var pagesArray = [];
        while(from <= to) {
            pagesArray.push(from);
            from++;
        }
        return pagesArray;
    },
    calcularTotal: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+(this.arrayDetalle[i].precionormal*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)
        }
        return resultado.toFixed(2);
    },

     calcularTotalc: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
        }
        return resultado.toFixed(2);
    },


    calcularTotalu: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
        }
        return resultado.toFixed(2);
    },

    calcularTotalP: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayReporte.length;i++){
             resultado +=parseFloat(this.arrayReporte[i].totalu)
        }
        return resultado;
    },
    
     calcularTotalvP: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayReporte.length;i++){

             resultado +=parseFloat(this.arrayReporte[i].total_neto)

        }
        return resultado;
    },

    contarTracking: function(){

       var resultado=0.0;
        for(var i=0;i<this.arrayReporte.length;i++){

            resultado =i+1;

        }
        return resultado;
    },


     totalMasEnvio: function(){

        var resultado=0.0;
        resultado= (this.calcularTotal) + (this.envio);
        return resultado.toFixed(2);

    },

     calcularGuia: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].total)

        }
        return resultado;
    },

    netoTotal: function(){

       var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].total_neto)

        }
        return parseFloat(resultado).toFixed(2);
    },

    contarGuia: function(){

       var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

            resultado =i+1;

        }
        return resultado;
    }
},
methods : {


/**************************************************************************************** */
      listarReporte(lugar,de,a){



      if (this.validarReporte()){
            return;
        }
        /*
        console.log(lugar);
        console.log(de);
        console.log(a);
    */
        let me=this;
         var url= '/tracking/listarP?lugar=' + lugar + '&de='+ de + '&a='+ a  ;
          axios.get(url).then(function (response) {
          console.log(response);

            var respuesta= response.data;
            me.arrayReporte = respuesta.reporte;





        })
        .catch(function (error) {
            console.log(error);
        });


    },

/**************************************************************************************** */
    buscarCliente(){
        let me=this;


        var url= '/cliente/selectCliente?filtro='+search;
        axios.get(url).then(function (response) {
            let respuesta = response.data;
            me.arrayCliente=respuesta.clientees;

        })
        .catch(function (error) {
            console.log(error);
        });
    },

/**************************************************************************************** */
    getDatosCliente(val1){
        let me = this;
        me.loading = true;
        me.idcliente = val1.id;
    },

 /**************************************************************************************** */
    buscarProducto(){
        let me=this;
        var url= '/producto/buscarProducto?filtro=' + me.nombre;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos;

            if (me.arrayProducto.length>0){
                me.producto=me.arrayProducto[0]['nombre'];
                me.idproducto=me.arrayProducto[0]['id'];
            }
            else{
                me.producto='No existe producto';
                me.idproducto=0;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },

     selectLugar(){
        let me=this;
        var url= '/lugar/selectLugar';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayLugar = respuesta.lugares;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

    selectLugar2(){
        let me=this;
        var url= '/lugar/selectLugar';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayLugar2 = respuesta.lugares;
        })
        .catch(function (error) {
            console.log(error);
        });
    },


  /**************************************************************************************** */

    cambiarPagina(page,buscar,criterio){
        let me = this;
        //Actualiza la página actual
        me.pagination.current_page = page;
        //Envia la petición para visualizar la data de esa página
        me.listarTracking(page,buscar,criterio,idlugar2);
    },

  /**************************************************************************************** */
    encuentra(id){
        var sw=0;
        for(var i=0;i<this.arrayDetalle.length;i++){
            if(this.arrayDetalle[i].idproducto==id){
                sw=true;
            }
        }
        return sw;
    },

  /**************************************************************************************** */
    eliminarDetalle(index){
        let me = this;
        me.arrayDetalle.splice(index, 1);
    },
   /**************************************************************************************** */
    agregarDetalle(){
        let me=this;
        if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
        }
        else{
            if(me.encuentra(me.idproducto)){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: me.idproducto,
                    producto: me.producto,
                    cantidad: me.cantidad,
                    precio: me.precio,
                    descuento: me.descuento,
                    stock: me.stock
                });
                me.codigo="";
                me.idproducto=0;
                me.producto="";
                me.cantidad=0;
                me.precio=0;
                me.descuento=0;
                me.stock=0
            }

        }



    },

     validarReporte(){



        this.errorReporte=0;
        this.errorMostrarMsjReporte  =[];

        if (this.de==""){
          this.errorMostrarMsjReporte.push("EL Rangos de Fecha esta Vacio.");

        }

        if (this.a==""){
           this.errorMostrarMsjReporte.push("Uno de los Rangos de Fecha esta Vacio.");

        }

        if (this.lugar==0){
          this.errorMostrarMsjReporte.push("Seleccione un Lugar.");

        }



        if (this.errorMostrarMsjReporte.length) this.errorReporte = 1;
            return this.errorReporte;


    },

     /**************************************************************************************** */
    agregarDetalleModal(data =[]){

        let me=this;
        if(me.encuentra(data['id'])){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: data['id'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_venta'],
                    descuento:0,
                    stock:data['stock']

                });
            }
    },

    /**************************************************************************************** */
    agregarClienteModal(data =[]){


        let me=this;


                        me.idcliente=data['id'];
                        me.nit = data['nit'];
                        me.nombrep = data['nombre'];
                        me.direccion = data['direccion'];
                        me.telefono = data['telefono'];
        this.cerrarModalB();

    },
     /**************************************************************************************** */
    listarProducto (buscarB,criterioB){
        let me=this;
        var url= '/producto/listarProducto?buscar='+ buscarB + '&criterio='+ criterioB;
        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },


    pdfTracking(id){
         window.open(this.urlhttp+'/tracking/pdf/'+id+','+'_blank');
    },


         /**************************************************************************************** */
    listarCliente (buscarB,criterioB){
         let me=this;
        var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayCliente = respuesta.cliente.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
     /**************************************************************************************** */

    registrarTracking(){
        if (this.validarTracking()){
            return;
        }

        let me = this;

        axios.post('/tracking/registrar',{
            'idcliente': this.idcliente,
            'tipo_comprobante': this.tipo_comprobante,
            'num_comprobante' : this.num_comprobante,
            'envio' : this.envio,
            'total' : this.total,
            'data': this.arrayDetalle

        }).then(function (response) {
            me.listado=1;
            me.listarTracking(1,'','num_comprobante',me.idlugar2);
            me.idcliente=0;
            me.tipo_comprobante='COD';
            me.num_comprobante='';
            me.envio=30;
            me.total=0.0;
            me.idproducto=0;
            me.producto='';
            me.cantidad=0;
            me.precio=0;
            me.descuento=0;
            me.arrayDetalle=[];

             me.cerrarModal();
            me.listarTracking(1,'','tipo_comprobante',me.idlugar2);
             Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Datos Agregados Con Exito!',
                  showConfirmButton: false,
                  timer: 1500

                });
/*
            setTimeout(function(){
                window.open('http://localhost:8000/tracking/pdf/'+response.data.id+','+'_blank');
             }, 1000);
*/

        }).catch(function (error) {
            console.log(error);
        });
    },

     /**************************************************************************************** */
    validarTracking(){
        this.errorTracking=0;
        this.errorMostrarMsjTracking =[];

        if (this.idcliente==0) this.errorMostrarMsjTracking.push("Seleccione un Cliente");
        if (this.tipo_comprobante==0) this.errorMostrarMsjTracking.push("Seleccione el comprobante");
        if (!this.num_comprobante) this.errorMostrarMsjTracking.push("Ingrese el número de comprobante");
        if (!this.envio) this.errorMostrarMsjTracking.push("Ingrese el envio de tracking");
        if (this.arrayDetalle.length<=0) this.errorMostrarMsjTracking.push("Ingrese detalles");

        if (this.errorMostrarMsjTracking.length) this.errorTracking = 1;

        return this.errorTracking;
    },

     /**************************************************************************************** */
    mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.tipo_comprobante='COD';
        me.num_comprobante='';
        me.envio=0.0;
        me.total=0.0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.arrayDetalle=[];
    },

     /**************************************************************************************** */
    ocultarDetalle(){
        this.listado=1;
    },

     /**************************************************************************************** */
    verTracking(id){
        let me=this;
        me.listado=2;

        //Obtener los datos del tracking
        var arrayTrackingT=[];
        var url= '/tracking/obtenerCabecera?id=' + id;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            arrayTrackingT = respuesta.tracking;

            me.cliente = arrayTrackingT[0]['nombre'];
            me.tipo_comprobante=arrayTrackingT[0]['tipo_comprobante'];
            me.num_comprobante=arrayTrackingT[0]['num_comprobante'];
            me.envio=arrayTrackingT[0]['envio'];
            me.total=arrayTrackingT[0]['total'];

        })
        .catch(function (error) {
            console.log(error);
        });

        //Obtener los datos de los detalles
        var urld= '/tracking/obtenerDetalles?id=' + id;

        axios.get(urld).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayDetalle = respuesta.detalles;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

     /**************************************************************************************** */
    cerrarModal(){
        this.modal=0;
        this.tituloModal='';
    },

     /**************************************************************************************** */
    abrirModal(){
        this.arrayProducto=[];
        this.modal = 1;
        this.tituloModal = 'Seleccione uno o varios productos';
    },

     /**************************************************************************************** */

 /**************************************************************************************** */


         cerrarModalB(){
        this.modalB=0;
        this.tituloModalB='';
    },
    abrirModalB(){
        this.arrayCliente=[];
        this.modalB = 1;
        this.tituloModalB = 'Seleccione el cliente';
    },

     mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.tipo_comprobante='COD';

        me.num_comprobante='';
        me.envio=30;
        me.total=0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.arrayDetalle=[];
    },
    ocultarDetalle(){
        this.listado=1;
    },

},
mounted() {
    this.selectLugar();


}
}
</script>
<style>
.modal-content{
width: 100% !important;
position: absolute !important;
}
.mostrar{
display: list-item !important;
opacity: 1 !important;
position: absolute !important;
background-color: #3c29297a !important;
}
.div-error{
display: flex;
justify-content: center;
}
.text-error{
color: red !important;
font-weight: bold;
}
@media (min-width: 600px) {
.btnagregar {
    margin-top: 2rem;
}
}

</style>
