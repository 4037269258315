<template>
    <main class="main">
    <!-- Content Header (Page header) -->
    <div class="content-header">
    <div class="container-fluid">
        <div class="mb-2 row">
        <div class="col-sm-6">

            <h1 class="m-0 text-dark">Comisiones de Guias</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">

        </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    </div>
    <div class="container-fluid">
        <!-- Ejemplo de tabla Listado -->
        <div class="card">



            <!------------------------------------------------- Listado Principal ------------------------------>
            <template v-if="listado==1">
            <div class="card-body">
                    <div class="form-group row">
           <div class="col-md-12">
             <div class="form-inline">

               <label for="FormControlSelect1">Usuario: </label>
               <select class="form-control" id="FormControlSelect1" v-model="usuario">
                <option value="0" disabled>Seleccione</option>
                <option v-for="usuario in arrayusuario" :key="usuario.id" :value="usuario.id" v-text="usuario.nombre"></option>
               </select>







                <label for="FormControlinput1">De: </label>
               <input id="FormControlinput1" type="date" class="form-control" v-model="de" >&nbsp;



                <label for="FormControlinput2">A: </label>
               <input id="FormControlinput2" type="date" class="form-control" v-model="a" >&nbsp;

                <button type="submit" class="btn btn-primary" @click.prevent="listarReporte(usuario,de,a)"><i class="fa fa-search"></i>&nbsp;Buscar</button>

             </div>&nbsp;
             <div>
            <button type="submit" class="btn btn-primary" :disabled = "deshabilitar_boton==0" @click.prevent="reporteComision(usuario,de,a)"><i class="fas fa-file-pdf"></i>&nbsp;Reporte</button>
             &nbsp;</div>

             <div>
                  <h5>Total Utilidad: Q {{calcularTotalP}}</h5>
                  <h5>Total Venta: Q {{calcularTotalVenta}}</h5>
                  <h5>Total Envio: Q {{calcularEnvio}}</h5>
                        <h6>Cantidad:  {{contarGuia}}</h6>

             </div>


              <div v-show="errorReporte" class="form-growp row div-error">
              <div class="text-center text-error">
                                <div v-for="error in errorMostrarMsjReporte" :key="error" v-text="error">

                                </div>
                            </div>
          </div>
           </div>
                    </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Opciones</th>
                                <th>Usuario</th>
                                <th>Cliente</th>
                                <th>Tipo Comprobante</th>
                                <th>Número Comprobante</th>
                                <th>Fecha Hora</th>
                                <th>Total</th>
                                <th>Envio</th>
                                <th>TotalGuia</th>
                             
                                <th>TotalCosto</th>
                                <th>Utilidad</th>

                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(guia,index) in arrayTracking" :key="guia.id">
                                <td>
                                    {{(index+1)}}
                                </td>
                                <td>
                                    <button type="button" @click.prevent="verguia(guia.id)" class="btn btn-success btn-sm">
                                    <i class="fas fa-eye"></i>
                                    </button> &nbsp;
                                    <button type="button" @click.prevent="pdfguia(guia.id)" class="btn btn-info btn-sm">
                                    <i class="fas fa-file-pdf"></i>
                                    </button> &nbsp;

                                </td>
                                <td v-text="guia.usuario"></td>
                                <td v-text="guia.nombre"></td>
                                <td v-text="guia.tipo_comprobante"></td>
                               
                                <td v-text="guia.num_comprobante"></td>
                                <td v-text="guia.fecha_hora"></td>
                                <td v-text="guia.total_neto"></td>
                              
                                <td v-text="guia.envio"></td>
                                <td v-text="guia.total"></td>
                                <td v-text="guia.totalc"></td>
                                <td v-text="guia.totalu"></td>

                                <td v-text="guia.estado"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul class="pagination">
                        <li class="page-item" v-if="pagination.current_page > 1">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                        </li>
                    </ul>
                </nav>
            </div>
            </template>
            <!--Fin Listado-->

        <!------------------------------------------------- Detalle ------------------------------>
            <template v-else-if="listado==0">
            <div class="card-body">
                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                            <div class="form-inline">

                                <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                <input type="text"   readonly class="form-control limpiar" v-model="nit">
                                <input type="text"   readonly class="form-control limpiar" v-model="nombrep">
                                <input type="text"   readonly class="form-control limpiar" v-model="direccion">
                                <input type="text"   readonly class="form-control limpiar" v-model="telefono">
                            </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo Comprobante(*)</label>
                            <select class="form-control" v-model="tipo_comprobante">
                                <option value="0">Seleccione</option>
                                <option value="BOLETA">Boleta</option>
                                <option value="FACTURA">Factura</option>
                                <option value="TICKET">Ticket</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Serie Comprobante</label>
                            <input type="text" disabled class="form-control" v-model="serie_comprobante" placeholder="000x">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante(*)</label>
                            <input type="text" disabled class="form-control" v-model="num_comprobante" placeholder="000xx">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div v-show="errorguia" class="form-group row div-error">
                            <div class="text-center text-error">
                                <div v-for="error in errorMostrarMsjguia" :key="error" v-text="error">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="border form-group row">
                     <div class="col-md-9">
                         <div class="form-group">
                               <label for="exampleFormControlSelect1">Tienda <span style="color:red;" v-show="idusuario==0">(*Seleccione)</span></label>
                               <select class="form-control" id="exampleFormControlSelect1" v-model="idusuario">
                                 <option value="0" disabled>Seleccione</option>
                                  <option v-for="usuario in arrayusuario" :key="usuario.id" :value="usuario.id" v-text="usuario.nombre"></option>
                                </select>
                             </div>
                     </div>
                </div>
                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                            <div class="form-inline">
                                <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                 <input  v-on:keyup.enter="buscarProducto2()" v-model="codigo" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                            </div>

                        </div>
                    </div>

                </div>
                <div class="col-md-4">
                        <div class="form-group">
                            <label>Total Articulos</label>
                            <p>{{totalArticulo}}</p>
                        </div>
                    </div>

                <div class="border form-group row">
                    <div class="table-responsive-md col-md-12">
                        <table  class="table table-bordered table-striped ">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Opciones</th>
                                    <th>Codigo</th>
                                    <th>Producto</th>
                                    <th id="costo">Costo</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    <th id="totalc">SubtotalCosto</th>
                                    <th id="totalu">SubtotalUtilidad</th>
                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>
                                        {{(index+1)}}
                                    </td>
                                    <td>
                                        <button @click="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                            <i class="fas fa-times-circle"></i>
                                        </button>
                                    </td>
                                    <td v-text="detalle.codigo">
                                    </td>
                                    <td v-text="detalle.producto">
                                    </td>
                                     <td>
                                        <input style="width: 100px" disabled v-model="detalle.costo" type="number" value="4" class="form-control" >
                                    </td>
                                    <td>

                                        <input style="width: 100px" v-model="detalle.precio" type="number" value="3" class="form-control" >
                                    </td>
                                    <td>
                                         <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                        <input v-model.number="detalle.cantidad"  type="number" value="2" class="form-control">
                                    </td>
                                    <td>
                                        <span style="color:red;" v-show="detalle.descuento>(detalle.precio*detalle.cantidad)">Descuento superior</span>
                                        <input v-model="detalle.descuento" type="number" class="form-control">
                                    </td>
                                    <td>
                                        {{detalle.precio*detalle.cantidad-detalle.descuento}}
                                    </td>
                                     <td id="totalc2">
                                        {{detalle.costo*detalle.cantidad}}
                                    </td>
                                     <td id="totalu2">
                                        {{(detalle.precio*detalle.cantidad-detalle.descuento)-(detalle.costo*detalle.cantidad)}}
                                    </td>
                                </tr>

                                <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total Parcial:</strong></td>
                                    <td>Q {{totalParcial=calcularTotal}}</td>
                                </tr>


                                <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total Neto:</strong></td>
                                    <td>Q {{total=calcularTotal}}</td>
                                </tr>
                                <tr  style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                    <td>Q {{totalc=calcularTotalc}}</td>
                                </tr>
                                <tr  style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                    <td>Q {{totalu=calcularTotalu}}</td>
                                </tr>
                            </tbody>




                            <tbody v-else>
                                <tr>
                                    <td colspan="5">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--  ---------  Tabla cotizar ----------->

                         <table id="producto"  v-show="cotizar" class="table table-bordered table-striped ">


                            <thead>
                                <tr>
                                   <th>No</th>
                                   <th>Codigo</th>
                                    <th>Producto</th>

                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>

                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>{{(index+1)}}</td>
                                    <td v-text="detalle.codigo"></td>
                                    <td v-text="detalle.producto"></td>

                                    <td v-text="detalle.precio"></td>
                                    <td v-text="detalle.cantidad"></td>
                                    <td v-text="detalle.descuento"></td>
                                    <td>
                                       Q {{detalle.precio*detalle.cantidad-detalle.descuento}}
                                    </td>

                                </tr>



                                <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total:</strong></td>
                                    <td>Q {{total=calcularTotal}}</td>
                                </tr>

                            </tbody>



                            <tbody v-else>
                                <tr>
                                    <td colspan="5">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        Total: Q{{calcularTotal}}<br>
                       Monto: <input type="number" :disabled = "deshabilitar_boton==1"  @keyup.enter="registrarguia()" v-model.number="recibido"><br>

                      Cambio Q{{cambio=recibido - calcularTotal}}<br>

                             <button type="button"  class="btn btn-primary" :disabled = "deshabilitar_boton==1"  @click="registrarguia()">Registrar guia</button>
                              <button type="button"  class="btn btn-warning"  @click="cotizacion()">cotización</button>
                             <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>


                    </div>
                </div>
            </div>
            </template>
            <!-- Fin Detalle-->

            <!------------------------------------------------- Ver guia_detalle ------------------------------>
            <template v-else-if="listado==2">
            <div class="card-body">
                <div class="border form-group row">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="">Cliente</label>
                            <p v-text="cliente"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo Comprobante</label>
                            <p v-text="tipo_comprobante"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante </label>
                            <p id="numTracking" v-text="num_comprobante"></p>

                        </div>
                    </div>
                     <div class="col-md-4">
                        <div class="form-group">
                            <label>Envio **</label>
                            <p v-text="envio"></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Total Articulos</label>
                            <p>{{totalArticulo}}</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Estado</label>
                            <p id="estado" v-text="estado"></p>

                        </div>
                    </div>
                </div>
                <div class="border form-group row">
                    <div class="table-responsive col-md-12">

                        <table class="table table-bordered table-striped table-sm">

                            <thead>
                                <tr>



                                    <th>No</th>
                                    <th>Codigo</th>

                                    <th>Genero</th>

                                    <th>Producto</th>
                                    <th>Costo</th>
                                    <th>Precio_normal</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    <th>SubtotalCosto</th>
                                    <th>SubtotalUlitidad</th>


                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>
                                        {{(index+1)}}
                                    </td>

                                    <td v-text="detalle.codigo">
                                    </td>

                                   <td v-text="detalle.genero">
                                    </td>


                                    <td v-text="detalle.articulo">
                                    </td>

                                    <td v-text="detalle.costo">
                                    </td>
                                    <td v-text="detalle.precionormal">
                                    </td>
                                    <td v-text="detalle.precio">
                                    </td>
                                    <td v-text="detalle.cantidad">
                                    </td>
                                    <td v-text="detalle.descuento">
                                    </td>
                                    <td>
                                        {{detalle.precio*detalle.cantidad}}
                                    </td>
                                    <td>
                                        {{detalle.costo*detalle.cantidad}}
                                    </td>
                                    <td>
                                        {{(detalle.precio*detalle.cantidad)-(detalle.costo*detalle.cantidad)}}
                                    </td>
                                </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>     
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{calcularTotal}}</td>
                                        </tr>
                                        
                                        

                               
                                <!--
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                    <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                </tr>
                            -->
                             <!--
                            

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{calcularTotalN}}</td>
                                        </tr>

                                       <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                              -->


                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                    </div>
                </div>
            </div>
            </template>
            <!-- fin ver guia -->
        </div>
        <!-- Fin ejemplo de tabla Listado -->
    </div>

<!------------------------------------------------- Modal agregar ------------------------------>

    <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-primary modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" v-text="tituloModal"></h4>
                    <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-md-6">

                            <div class="input-group">

                                <select class="form-control col-md-3" v-model="criterioA">
                                <option value="codigo">Codigo</option>
                                <option value="nombre">Nombre</option>
                                <option value="descripcion">Descripción</option>
                                </select>
                                <input type="text" v-model="buscarA" @keyup.enter="listarProducto(1,buscarA,criterioA,idusuario)" class="form-control" placeholder="Texto a buscar">
                                <button type="submit" @click="listarProducto(1,buscarA,criterioA,idusuario)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                            </div>

                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Opciones</th>
                                    <th>Marca</th>
                                    <th>Codigo</th>
                                    <th>Nombre</th>
                                    <th>Descripcion</th>

                                    <th>Precio guia</th>
                                    <th>Precio Costo</th>
                                    <th>Stock</th>
                                    <th>Estado</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="producto in arrayProducto" :key="producto.id">
                                    <td>
                                        <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                        <i class="fas fa-check-circle"></i>
                                        </button>
                                    </td>
                                   <td v-text="producto.nombre_marca"></td>
                                   <td v-text="producto.codigo"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.nombre"></td>
                                    <td v-else v-text="producto.nombre"></td>
                                     <td v-text="producto.descripcion"></td>
                                    <td v-text="producto.precio_guia"></td>
                                    <td v-text="producto.precio_costo"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                     <td v-else v-text="producto.stock"></td>
                                    <td>
                                        <div v-if="producto.condicion">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                        <div v-else>
                                            <span class="badge badge-danger">Desactivado</span>
                                        </div>

                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <nav>
                        <ul class="float-right m-0 pagination pagination-sm">
                        <li class="page-item" v-if="pagination2.current_page > 1">
                            <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarA,criterioA)" >&laquo;</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                            <a class="page-link" href="#" @click="cambiarPagina2(page,buscarA,criterioA)" v-text="page"></a>
                            </li>
                        <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                            <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarA,criterioA)">&raquo;</a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cerrarModal()">Cerrar</button>

                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!--Fin del modal-->




    <div tabindex="-1" class="p-5 modal fade" :class="{'mostrar' : modal2}" >
<div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header bg-primary">
      <h4 class="modal-title" v-text="tituloModal2"></h4>
      <button type="button" class="close" @click="cerrarModal()" >
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body">

           <div class="card-body table-responsive">
        <table  class="table table-bordered table-striped">
          <thead>
          <tr>


            <th>Nombre</th>
            <th>Stock</th>
            <th>Descripcion</th>
            <th>Minimo</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="producto in arrayProducto" :key="producto.id" >


                            <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                            <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>




          </tr>


          </tbody>
          <tfoot>

          </tfoot>
        </table>
        <div class="clearfix card-footer">
         <nav>
        <ul class="float-right m-0 pagination pagination-sm">
          <li class="page-item" v-if="pagination.current_page > 1">
            <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
         </li>
          <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
            <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
            </li>
          <li class="page-item" v-if="pagination.current_page < pagination.last_page">
            <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
            </li>
        </ul>
        </nav>
      </div>
    </div>

    </div>
    <div class="modal-footer ">
      <button type="button" class="btn btn-default"  @click="cerrarModal()">Close</button>
    </div>
  </div>
  <!-- /.modal-content -->
</div>
<!-- /.modal-dialog -->
</div>
<!-- /.modal -->




     <!------------------------------------------------- Modal Cliente ------------------------------>

    <div class="p-5 modal fade" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-primary modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" v-text="tituloModalB"></h4>
                    <button type="button" class="close" @click="cerrarModalB()" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <select class="form-control col-md-3" v-model="criterioB">
                                <option value="nit">Nit</option>
                                <option value="nombre">Nombre</option>

                                </select>
                                <input type="text" v-model="buscarB" @keyup.enter="listarCliente(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                <button type="submit" @click="listarCliente(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table  class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Opciones</th>
                                    <th>Nit</th>
                                    <th>Nombre</th>
                                    <th>Direccion</th>
                                    <th>Telefono</th>
                                     <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cliente in arrayCliente" :key="cliente.id">
                                    <td>
                                        <button type="button" @click.prevent="agregarClienteModal(cliente)" class="btn btn-success btn-sm">
                                        <i class="fas fa-check-circle"></i>
                                        </button>
                                    </td>

                                    <td v-text="cliente.nit"></td>
                                    <td v-text="cliente.nombre"></td>
                                    <td v-text="cliente.direccion"></td>
                                    <td v-text="cliente.telefono"></td>
                                    <td>
                                        <div v-if="cliente.condicion">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                        <div v-else>
                                            <span class="badge badge-danger">Desactivado</span>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cerrarModalB()">Cerrar</button>
                    <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarCliente()">Guardar</button>
                    <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarCliente()">Actualizar</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!--Fin del modal-->
</main>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
data (){
    return {
        guia_id: 0,
        idcliente:0,
        usuario:0,
        de:"",
        a:"",
        nit: '',
        usuario:'',
        cotizar:0,
        cliente:'',
        codigo:'',
        nombre : '',
        nombrep : '',
        direccion : '',
        telefono : '',
        tipo_comprobante : 'TICKET',
        serie_comprobante : '',
        num_comprobante : '',
        estado : '',
        urlhttp: this.$api_url,
        deshabilitar_boton:0,
        impuesto: 0.0,
        total:0.0,
        totalc:0.0,
        totalu:0.0,
        
        envio: '',
        totalEnvio: 0.0,
        totalImpuesto: 0.0,
        totalParcial: 0.0,
        arrayTracking : [],
        arrayCliente: [],
        arrayDetalle : [],
        arrayusuario : [],
         arrayusuario2 : [],
         arrayAnularV: [],
        listado:1,
        modal : 0,
        modalB : 0,
         modal2 : 0,
        tituloModal : '',
        tituloModalB : '',
         tituloModal2 : '',
        tipoAccion : 0,
        errorguia : 0,
        errorMostrarMsjguia : [],
          errorReporte : 0,
        errorMostrarMsjReporte : [],
        pagination : {
            'total' : 0,
            'current_page' : 0,
            'per_page' : 0,
            'last_page' : 0,
            'from' : 0,
            'to' : 0,
        },
        offset : 3,

         pagination2 : {
            'total' : 0,
            'current_page' : 0,
            'per_page' : 0,
            'last_page' : 0,
            'from' : 0,
            'to' : 0,
        },
        offset2 : 3,
        criterio : 'fecha_hora',
        buscar : '',
        criterioA:'nombre',
        buscarA: '',
        criterioB:'nombre',
        buscarB: '',
        arrayProducto: [],
        idproducto: 0,
       // codigo: '',
        producto: '',
        precio: 0,
        cantidad:0,
        descuento: 0,
        cambio: 0,
        negativo: 0,
        stock: 0
    }
},
components: {

},
computed:{
    isActived: function(){
        return this.pagination.current_page;
    },
    isActived2: function(){
        return this.pagination2.current_page;
    },
    //Calcula los elementos de la paginación
    pagesNumber: function() {
        if(!this.pagination.to) {
            return [];
        }

        var from = this.pagination.current_page - this.offset;
        if(from < 1) {
            from = 1;
        }

        var to = from + (this.offset * 2);
        if(to >= this.pagination.last_page){
            to = this.pagination.last_page;
        }

        var pagesArray = [];
        while(from <= to) {
            pagesArray.push(from);
            from++;
        }
        return pagesArray;
    },
    pagesNumber2: function() {
        if(!this.pagination2.to) {
            return [];
        }

        var from = this.pagination2.current_page - this.offset2;
        if(from < 1) {
            from = 1;
        }

        var to = from + (this.offset2 * 2);
        if(to >= this.pagination2.last_page){
            to = this.pagination2.last_page;
        }

        var pagesArray2 = [];
        while(from <= to) {
            pagesArray2.push(from);
            from++;
        }
        return pagesArray2;
    },

    totalDescuento: function(){
        var resultado=0.0;

        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado+=parseFloat( this.arrayDetalle[i].descuento)
        }
        return resultado;

            },
            
     calcularTotalP: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].totalu)

        }
        return resultado;
    },
    calcularTotalVenta: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].total_neto)

        }
        return resultado;
    },
     calcularTotalvP: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].total)

        }
        return resultado;
    },
    calcularEnvio: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

             resultado +=parseFloat(this.arrayTracking[i].envio)

        }
        return resultado;
    },
    contarGuia: function(){

       var resultado=0.0;
        for(var i=0;i<this.arrayTracking.length;i++){

            resultado =i+1;

        }
        return resultado;
    },
   
    calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },



    calcularTotalc: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
        }
        return resultado;
    },


    calcularTotalu: function(){
        var resultado=0.0;
        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad-this.arrayDetalle[i].descuento)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
        }
        return resultado;
    },


    totalArticulo: function(){
        var result=0;
         for(var i=0;i<this.arrayDetalle.length;i++){

            result+=(this.arrayDetalle[i].cantidad)
        }
        return result;


    },

    contarAlerta: function(){

      var resultado=0;
        for(var i=0;i<this.arrayProducto.length;i++){

            if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

              resultado +=1;
            }


        }
        return resultado;
    }
},
methods : {


/**************************************************************************************** */
/*
listarguia (page,buscar,criterio,idusuario2){

        let me=this;
        var url= '/guia?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idusuario2='+ idusuario2;
        axios.get(url).then(function (response) {
            console.log(response);
            var respuesta= response.data;
            me.arrayTracking = respuesta.guias.data;
            me.pagination= respuesta.pagination;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    */

    listarReporte(usuario,de,a){



      if (this.validarReporte()){
            return;
        }
        /*
        console.log(usuario);
        console.log(de);
        console.log(a);
    */

   this.deshabilitar_boton=1;
        let me=this;
         var url= '/comisionreporteGuia?usuario=' + usuario + '&de='+ de + '&a='+ a  ;
          axios.get(url).then(function (response) {
          //console.log(response);

            var respuesta= response.data;
            me.arrayTracking = respuesta.reporte;





        })
        .catch(function (error) {
            console.log(error);
        });


    },

/**************************************************************************************** */
    buscarCliente(){
        let me=this;


        var url= '/cliente/selectCliente?filtro='+search;
        axios.get(url).then(function (response) {
            let respuesta = response.data;
            me.arrayCliente=respuesta.clientees;

        })
        .catch(function (error) {
            console.log(error);
        });
    },

/**************************************************************************************** */
    getDatosCliente(val1){
        let me = this;
        me.loading = true;
        me.idcliente = val1.id;
    },

 /**************************************************************************************** */
    buscarProducto(){
        let me=this;
        var url= '/existencia/buscarProducto?filtro=' + me.nombre;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos;

            if (me.arrayProducto.length>0){
                me.producto=me.arrayProducto[0]['nombre'];
                me.idproducto=me.arrayProducto[0]['id'];
            }
            else{
                me.producto='No existe producto';
                me.idproducto=0;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },

    buscarProducto2(){
          let me=this;
       var url= '/existencia/buscarProducto2?filtro=' + me.codigo;



        axios.get(url).then(function (response) {


            var respuesta= response.data;
            me.arrayProducto = respuesta.productos;





            if (me.arrayProducto.length>0){
                me.codigo=me.arrayProducto[0]['codigo'];
                me.idproducto=me.arrayProducto[0]['id'];
                me.nombre=me.arrayProducto[0]['nombre'];
                me.costo=me.arrayProducto[0]['precio_costo'];
                me.precio=me.arrayProducto[0]['precio_guia'];
                me.stock= me.arrayProducto[0]['stock'];

         if(me.encuentra(me.arrayProducto[0]['id']) ){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }else{
                me.arrayDetalle.push({
                    idproducto: me.arrayProducto[0]['id'],
                    codigo: me.arrayProducto[0]['codigo'],
                    producto: me.arrayProducto[0]['nombre'],
                    cantidad: 1,
                    costo: me.arrayProducto[0]['precio_costo'],
                    precio: me.arrayProducto[0]['precio_guia'],
                    descuento: 0,
                    stock: me.arrayProducto[0]['stock'],
                });
            }


/*
                 if(me.arrayDetalle[i].idproducto==me.arrayProducto[0]['id']){

                     me.arrayDetalle.push({
                         cantidad: cantidad+1
                         });

                 }else{

                     me.arrayDetalle.push({
                    idproducto: me.arrayProducto[0]['id'],
                    codigo: me.arrayProducto[0]['codigo'],
                    producto: me.arrayProducto[0]['nombre'],
                    cantidad: 1,
                    costo: me.arrayProducto[0]['precio_costo'],
                    precio: me.arrayProducto[0]['precio_guia'],
                    descuento: 0,
                    stock: me.arrayProducto[0]['stock'],
                });

                 }

            */


                /*
                 me.arrayDetalle.push({
                    idproducto: data['id'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_guia'],
                    costo: data['precio_costo'],
                    descuento:0,
                    stock:data['stock']

                });
                */
               /*
                console.log(me.idproducto);
                console.log(me.codigo);
               console.log(me.nombre);
               console.log(me.costo);
               console.log(me.precio);
               console.log(me.stock);
               */
            }
            else{
                me.producto='No existe producto';
                me.idproducto=0;


            }
        })
        .catch(function (error) {
            console.log(error);
        });

    },


     selectusuario(){
        let me=this;
        var url= '/usuario/selectusuario';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayusuario = respuesta.usuario;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

    selectusuario2(){
        let me=this;
        var url= '/usuario/selectusuario';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayusuario2 = respuesta.usuarioes;
        })
        .catch(function (error) {
            console.log(error);
        });
    },




  /**************************************************************************************** */

    cambiarPagina(page,buscar,criterio,idusuario2){
        let me = this;
        //Actualiza la página actual
        me.pagination.current_page = page;
        //Envia la petición para visualizar la data de esa página
        me.listarguia(page,buscar,criterio,idusuario2);
    },

     cambiarPagina2(page,buscarA,criterioA,idusuario2){
        let me = this;
        //Actualiza la página actual
        me.pagination2.current_page = page;
        //Envia la petición para visualizar la data de esa página
        me.listarProducto(page,buscarA,criterioA,idusuario);
    },

  /**************************************************************************************** */
    encuentra(id){
        var sw=0;
        for(var i=0;i<this.arrayDetalle.length;i++){
            if(this.arrayDetalle[i].idproducto==id){
                sw=true;
            }
        }
        return sw;
    },

  /**************************************************************************************** */
    eliminarDetalle(index){
        let me = this;
        me.arrayDetalle.splice(index, 1);
    },


   /**************************************************************************************** */
    agregarDetalle(){
        let me=this;
        if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
        }
        else{
            if(me.encuentra(me.idproducto)){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: me.idproducto,
                    codigo: me.codigo,
                    producto: me.producto,
                    cantidad: me.cantidad,
                    precio: me.precio,
                    costo: me.costo,
                    descuento: me.descuento,
                    stock: me.stock
                });
                me.codigo="";
                me.idproducto=0;
                me.producto="";
                me.cantidad=0;
                me.precio=0;
                me.costo=0;
                me.descuento=0;
                me.stock=0
            }

        }



    },

     /**************************************************************************************** */
    agregarDetalleModal(data =[]){

        let me=this;
        if(me.encuentra(data['id'])){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: data['id'],
                     codigo: data['codigo'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_guia'],
                    costo: data['precio_costo'],
                    descuento:0,
                    stock:data['stock']

                });
            }
    },

    agregarDetalleModal2(data =[]){

        let me=this;
        if(me.encuentra(data['id'])){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: data['id'],
                    codigo: data['codigo'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_guia'],
                    costo: data['precio_costo'],
                    descuento:0,
                    stock:data['stock']

                });
            }
    },

    /**************************************************************************************** */
    agregarClienteModal(data =[]){


        let me=this;


                        me.idcliente=data['id'];
                        me.nit = data['nit'];
                        me.nombrep = data['nombre'];
                        me.direccion = data['direccion'];
                        me.telefono = data['telefono'];
        this.cerrarModalB();

    },
     /**************************************************************************************** */
    listarProducto (page,buscarA,criterioA,idusuario){
        let me=this;
        var url= '/existencia/listarProducto?page=' + page + '&buscar='+ buscarA + '&criterio='+ criterioA+ '&idusuario='+ idusuario;
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos.data;
            me.pagination2= respuesta.pagination2;
        })
        .catch(function (error) {
            console.log(error);
        });
    },


    pdfguia(id){

      //   window.open(this.urlhttp+'/guia/pdf/'+id+','+'_blank');
      window.open('/tracking/pdf/'+id+','+'_blank');

    },


   cotizacion(){



          if (this.validarCotizacion()){
            return;
        }


document.getElementById("costo").style.display === "none";
document.getElementById("totalc").style.display === "none";
document.getElementById("totalu").style.display === "none";
document.getElementById("totalc2").style.display === "none";
document.getElementById("totalu2").style.display === "none";


        Swal.fire({
            title: 'Cotizacion a Nombre de:',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            showLoaderOnConfirm: true,
            preConfirm: (login) => {

                var f = new Date();

                var fecha = (f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear());

                 this.cotizar==1;



       const doc = new jsPDF();
                doc.setFontSize(12);
                doc.text("Titulos", 20, 5);
                 doc.setFontSize(7);
                doc.text("DIRECCION:", 20, 8);
                 doc.setFontSize(7);
                doc.text("TEL:", 20, 12);



                doc.setFontSize(10);
                doc.text("Cotizacion a Nombre De: "+login,100,5);
                doc.setFontSize(7);
                doc.text("Fecha: "+fecha,185,5);
                doc.addImage("img/logo.jpg", "PNG", 5, 2, 10, 10);
                doc.setFontSize(7);
                doc.text("VIGENCIA DE COTIZACION Tiempo DESPUES DE SU EMISION",100,10);

                doc.autoTable({html: '#producto'});


        doc.save("cotizacion.pdf");

                this.cotizar==0;



            },
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: `${result.value.login}'s avatar`,
                imageUrl: result.value.avatar_url
                })
            }
            })

document.getElementById("costo").style.display === "block";
document.getElementById("totalc").style.display === "block";
document.getElementById("totalu").style.display === "block";
document.getElementById("totalc2").style.display === "block";
document.getElementById("totalu2").style.display === "block";


   },

         /**************************************************************************************** */
    listarCliente (buscarB,criterioB){
         let me=this;
        var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayCliente = respuesta.cliente.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
     /**************************************************************************************** */




/*
    registrarguia(){

        let cambio= this.total - this.recibido;


       // validarUser();


            if (this.validarguia()){
            return;
        }


        let me = this;
        me.deshabilitar_boton=1;

        axios.post('/guia/registrar',{
            'idcliente': this.idcliente,
            'tipo_comprobante': this.tipo_comprobante,
            'recibido': this.recibido,
            'cambio': this.cambio,
            'idusuario' : this.idusuario,

            //'serie_comprobante' : this.serie_comprobante,
            //'num_comprobante' : this.num_comprobante,
            //'impuesto' : this.impuesto,
            'totalc' : this.totalc,
            'totalu' : this.totalu,
            'total' : this.total,
            'data': this.arrayDetalle

        }).then(function (response) {
           //console.log(response);
            me.listado=1;
            me.listarguia(1,'','fecha_hora',me.idusuario2);

            me.idcliente=0;

            me.tipo_comprobante='TICKET';
            //me.serie_comprobante='';
            //me.num_comprobante='';
            //me.impuesto=0.18;
            me.total=0.0;
            me.totalc=0.0;
            me.totalu=0.0;
            me.idproducto=0;
            me.producto='';
            me.cantidad=0;
            me.costo=0;
            me.precio=0;
            me.descuento=0;
            me.recibido=0;
            me.arrayDetalle=[];
            me.arrayCliente=[];



             me.cerrarModal();




            Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Datos Agregados Con Exito!',
                  showConfirmButton: false,
                  timer: 1000

                });

                setTimeout(function(){


                    window.open('http://127.0.0.1:8000/guia/pdf/'+response.data.id+','+'_blank');


                }, 500);


           me.deshabilitar_boton=0;

        }).catch(function (error) {
            console.log(error);
        });


    },
*/
     /**************************************************************************************** */

    validarUser(){

       // window.location.href = '/logout';

/*
        axios.post('/user/activado',{


        }).then(function (response) {

            console.log(response);
          if(response){

          }
          else{

                window.location.href = 'https://127.0.0.1:8000/logout';



          }




        }).catch(function (error) {
            console.log(error);
        });
*/
    },

/*
    validarguia(){
        this.errorguia=0;
        this.errorMostrarMsjguia =[];

        if (this.idcliente==0) this.errorMostrarMsjguia.push("Seleccione un Cliente");
        if (this.idusuario==0) this.errorMostrarMsjguia.push("Seleccione una Tienda");
        if (this.tipo_comprobante==0) this.errorMostrarMsjguia.push("Seleccione el comprobante");

       // if (!this.num_comprobante) this.errorMostrarMsjguia.push("Ingrese el número de comprobante");
        //if (!this.impuesto) this.errorMostrarMsjguia.push("Ingrese el impuesto de guia");
        if (this.arrayDetalle.length<=0) this.errorMostrarMsjguia.push("Ingrese detalles");

        if(this.recibido==0) {
           this.errorMostrarMsjguia.push("El Monto recibido no puede ser 0");
        }
        if(this.cambio<0) {
            this.errorMostrarMsjguia.push("El Cambio no puede ser Negativo");
        }

        if (this.errorMostrarMsjguia.length) this.errorguia = 1;

        return this.errorguia;
    },


    validarCotizacion(){
        this.errorguia=0;
        this.errorMostrarMsjguia =[];


       // if (!this.num_comprobante) this.errorMostrarMsjguia.push("Ingrese el número de comprobante");
        //if (!this.impuesto) this.errorMostrarMsjguia.push("Ingrese el impuesto de guia");
        if (this.arrayDetalle.length<=0) this.errorMostrarMsjguia.push("Ingrese detalles");



        if (this.errorMostrarMsjguia.length) this.errorguia = 1;

        return this.errorguia;
    },
*/
validarReporte(){



        this.errorReporte=0;
        this.errorMostrarMsjReporte  =[];

        if (this.de==""){
          this.errorMostrarMsjReporte.push("EL Rangos de Fecha esta Vacio.");

        }

        if (this.a==""){
           this.errorMostrarMsjReporte.push("Uno de los Rangos de Fecha esta Vacio.");

        }

        if (this.usuario==0){
          this.errorMostrarMsjReporte.push("Seleccione un usuario.");

        }



        if (this.errorMostrarMsjReporte.length) this.errorReporte = 1;
            return this.errorReporte;


    },
     /**************************************************************************************** */
    mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.nit='';
        me.nombre='';
        me.direccion='';
        me.telefono='';
        me.tipo_comprobante='TICKET';
        me.serie_comprobante='';
        me.num_comprobante='';
        //me.impuesto=0.18;
        me.total=0.0;
        me.totalc=0.0;
        me.totalu=0.0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.arrayDetalle=[];
    },

     /**************************************************************************************** */
    ocultarDetalle(){
        this.listado=1;
    },

     /**************************************************************************************** */
    verguia(id){
        let me=this;
        me.listado=2;

        //Obtener los datos del tracking
        var arrayTrackingT=[];
        var url= '/tracking/obtenerCabecera?id=' + id;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            arrayTrackingT = respuesta.tracking;

            me.cliente = arrayTrackingT[0]['nombre'];
            me.tipo_comprobante=arrayTrackingT[0]['tipo_comprobante'];

            me.envio=arrayTrackingT[0]['envio'];

            me.num_comprobante=arrayTrackingT[0]['num_comprobante'];
            me.estado=arrayTrackingT[0]['estado'];
            //me.impuesto=arrayTrackingT[0]['impuesto'];

            me.total=arrayTrackingT[0]['total'];
            me.totalc=arrayTrackingT[0]['totalc'];
            me.totalu=arrayTrackingT[0]['totalu'];

        })
        .catch(function (error) {
            console.log(error);
        });

        //Obtener los datos de los detalles
        var urld= '/tracking/obtenerDetalles?id=' + id;

        axios.get(urld).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayDetalle = respuesta.detalles;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

     /**************************************************************************************** */
    cerrarModal(){
        this.modal=0;
        this.tituloModal='';
        this.modal2=0;
        this.recibido='';
        this.cambio='';
        this.desbloquear=0;


    },

     /**************************************************************************************** */
    abrirModal(){
        this.arrayProducto=[];
        this.modal = 1;
        this.tituloModal = 'Seleccione uno o varios productos';
    },


     abrirModalAlarma(modelo2, accion, data = []){
      switch(modelo2){
            case "producto":
            {
              switch(accion){
                case 'alarma':
                    {
                      this.modal2=1;
                      this.tituloModal2='Stock Minimo';
                      break;
                    }
              }
            }
      }

    },

     /**************************************************************************************** */
     /*
    desactivarguia(id){
       swal.fire({
        title: 'Esta seguro de anular este guia?',

        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
        }).then((result) => {
        if (result.value) {
            let me = this;

            axios.put('/guia/desactivar',{
                'id': id
            }).then(function (response) {
                me.listarguia(1,'','tipo_comprobante');
                swal.fire(
                'Anulado!',
                'El guia ha sido anulado con éxito.',
                'success'
                )
            }).catch(function (error) {
                console.log(error);
            });


        } else if (
            // Read more about handling dismissals
            result.dismiss === swal.DismissReason.cancel
        ) {

        }
        })
    },
*/
/*
     desactivarguia(id,num_comprobante,total){

         let me = this;
         let usuario2 = this.idusuario2;


         var urld= '/guia/obtenerDetalles?id=' + id +'&num_comprobante='+num_comprobante;

        axios.get(urld).then(function (response) {
            console.log(response);
            var respuesta= response.data;
            me.arrayAnularV = respuesta.detalles;



        })
        .catch(function (error) {
            console.log(error);
        });

     this.detallelist(id,usuario2,num_comprobante,total);


        },

*/
 /**************************************************************************************** */
/*
detallelist(id,usuario2,num_comprobante,total){

       Swal.fire({
        title: 'Esta seguro/a que desea Anular esta guia?',

        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {


        if (result.value) {
          let me = this;

        axios.put('/guia/desactivar',{
                  'id': id,
                  'num_comprobante':num_comprobante,
                  'total':total,
                  'usuario2': usuario2,
                  'data': this.arrayAnularV


                }).then(function(response){
                  //  console.log(response);
                  me.listarguia(1,'','fecha_hora',lugar2);
                  me.arrayAnularV=[];
                  Swal.fire(
                    'Anulado!',
                    'El registro a sido desactivado con exito.',
                    'success'
                  )
                }).catch(function(error){
                  console.log(error);
                });
         // console.log(this.arrayAnularV);
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      })

   },

*/
         cerrarModalB(){
        this.modalB=0;
        this.tituloModalB='';
    },
    abrirModalB(){
        this.arrayCliente=[];
        this.modalB = 1;
        this.tituloModalB = 'Seleccione el cliente';
    },

     mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.nombre='';
        me.direccion='';
        me.telefono='';
        me.tipo_comprobante='TICKET';
        me.serie_comprobante='';
        me.num_comprobante='';
        //me.impuesto=0.18;
        me.total=0.0;
        me.totalc=0.0;
        me.totalu=0.0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.costo=0;
        me.arrayDetalle=[];
    },
    ocultarDetalle(){
        this.listado=1;
    },

    reporteComision(usuario,de,a){

        if (this.validarReporte()){
            return;
        }

        let cantidad = this.contarguia;
        let total = this.calcularTotalvP;

        let comision = [usuario,de,a,cantidad,total];

        // window.open(this.urlhttp+'/comision/pdf/'+comision+','+'_blank');

         window.open('/comisionguia/pdf/'+comision+','+'_blank');


         this.deshabilitar_boton=0;

    },

},
mounted() {
   // this.selectLugar2();
   // this.listarguia(1,this.buscar,this.criterio,this.idlugar2);
    this.selectusuario();

}
}
</script>
<style>
.modal-content{
width: 100% !important;
position: absolute !important;
}
.mostrar{
display: list-item !important;
opacity: 1 !important;
position: absolute !important;
background-color: #3c29297a !important;
}
.div-error{
display: flex;
justify-content: center;
}
.text-error{
color: red !important;
font-weight: bold;
}
@media (min-width: 600px) {
.btnagregar {
    margin-top: 2rem;
}
}

</style>
