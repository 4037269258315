<template>
            <main class="main">
            <!-- Content Header (Page header) -->
            <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Compra</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    
                </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            </div>
            <div class="container-fluid">
                <!-- Ejemplo de tabla Listado -->
                <div class="card">
                    <div class="card-header">
                        <i class="fa fa-align-justify"></i> Ingresos
                        <button type="button" @click.prevent.prevent="mostrarDetalle()" class="btn btn-success">
                            <i class="fas fa-plus-square"></i>&nbsp;Nuevo
                        </button>
                    </div>
                    <!------------------------------------------------- Listado Principal ------------------------------>
                    <template v-if="listado==1">
                    <div class="card-body">
                        <div class="form-group row">
                            <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleFormControlSelect1">Tienda</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar2">
                                    <option value="0" disabled>Seleccione</option>
                                            <option v-for="lugar in arrayLugar2" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                                    </select>
                                    </div>
                                <div class="input-group">
                                    <select class="form-control col-md-3" v-model="criterio">
                                      <option value="tipo_comprobante">Tipo Comprobante</option>
                                      <option value="num_comprobante">Número Comprobante</option>
                                      <option value="fecha_hora">Fecha-Hora</option>
                                    </select>
                                    <input type="text" v-model="buscar" @keyup.enter="listarIngreso(1,buscar,criterio,idlugar2)" class="form-control" placeholder="Texto a buscar">
                                    <button type="submit" @click.prevent="listarIngreso(1,buscar,criterio,idlugar2)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Opciones</th>
                                        <th>Usuario</th>
                                        <th>Proveedor</th>
                                        <th>Tipo Comprobante</th>
                                        <th>Serie Comprobante</th>
                                        <th>Número Comprobante</th>
                                        <th>Fecha Hora</th>
                                        <th>Total</th>
                                        
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ingreso,index) in arrayIngreso" :key="ingreso.id">
                                        <td>{{(index + 1)}}</td>
                                        <td>
                                            <button type="button" @click.prevent="verIngreso(ingreso.id)" class="btn btn-success btn-sm">
                                            <i class="fas fa-eye"></i>
                                            </button> &nbsp;
                                            <template v-if="ingreso.estado=='Registrado'">
                                                <button type="button" class="btn btn-danger btn-sm" @click.prevent="desactivarIngreso(ingreso.id,ingreso.num_comprobante,ingreso.total)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </template>
                                        </td>
                                        
                                        <td v-text="ingreso.usuario"></td>
                                        <td v-text="ingreso.nombre"></td>
                                        <td v-text="ingreso.tipo_comprobante"></td>
                                        <td v-text="ingreso.serie_comprobante"></td>
                                        <td v-text="ingreso.num_comprobante"></td>
                                        <td v-text="ingreso.fecha_hora"></td>
                                        <td v-text="ingreso.total"></td>
                                       
                                        <td v-text="ingreso.estado"></td>
                                    </tr>                                
                                </tbody>
                            </table>
                        </div>
                        <nav>
                            <ul class="pagination">
                                <li class="page-item" v-if="pagination.current_page > 1">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar2)">Ant</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio,idlugar2)" v-text="page"></a>
                                </li>
                                <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                                    <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar2)">Sig</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    </template>
                    <!--Fin Listado-->

                <!------------------------------------------------- Detalle ------------------------------>
                    <template v-else-if="listado==0">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Proveedor <span style="color:red;" v-show="idproveedor==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModalC()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                                        <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                        <input type="text" readonly class="form-control" v-model="nit">
                                        <input type="text" readonly class="form-control" v-model="nombrep">
                                        <input type="text" readonly class="form-control" v-model="direccion">
                                        <input type="text" readonly class="form-control" v-model="telefono">
                                    </div>                                    
                              </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante(*)</label>
                                    <select class="form-control" v-model="tipo_comprobante">
                                        <option value="0">Seleccione</option>
                                        <option value="BOLETA">Boleta</option>
                                        <option value="FACTURA">Factura</option>
                                        <option value="TICKET">Ticket</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Serie Comprobante</label>
                                    <input type="text" class="form-control" v-model="serie_comprobante" placeholder="000x">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante(*)</label>
                                    <input type="text" class="form-control" v-model="num_comprobante" placeholder="000xx">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div v-show="errorIngreso" class="form-group row div-error">
                                    <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjIngreso" :key="error" v-text="error">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row border">
                             <div class="col-md-9">
                                 <div class="form-group">
                                       <label for="exampleFormControlSelect1">Tienda <span style="color:red;" v-show="idlugar==0">(*Seleccione)</span></label>
                                       <select class="form-control" id="exampleFormControlSelect1" v-model="idlugar">
                                         <option value="0" disabled>Seleccione</option>
                                          <option v-for="lugar in arrayLugar" :key="lugar.id" :value="lugar.id" v-text="lugar.nombre"></option>
                                        </select>
                                     </div>
                             </div>
                        </div> 

                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                                    <div class="form-inline">
                                        <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                    </div>                                    
                                </div>
                            </div>
                           
                        </div>

                        <div class="form-group row border">
                            <div class="table-responsive col-md-12">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Codigo</th>
                                            <th>Producto</th>
                                            <th>Precio_Costo</th>
                                            <th>Cantidad</th>
                                            <th>Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                           
                                            <td>
                                                <button @click.prevent="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                                    <i class="fas fa-times-circle"></i>
                                                </button>
                                            </td>
                                            <td v-text="detalle.codigo">
                                            </td>
                                            
                                            <td v-text="detalle.producto">
                                            </td>
                                             <td>
                                                <input style="width: 100px"  v-model="detalle.costo" type="number" value="3" class="form-control">
                                            </td>
                                            
                                            <td>
                                                <input v-model="detalle.cantidad" type="number" value="2" class="form-control">
                                            </td>
                                            <td>
                                                {{detalle.costo*detalle.cantidad}}
                                            </td>
                                        </tr>
                                        <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{totalParcial=(total-totalImpuesto).toFixed(2)}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Impuesto:</strong></td>
                                            <td>Q {{totalImpuesto=((total*impuesto)/(1+impuesto)).toFixed(2)}}</td>
                                        </tr>
                                        -->
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="4" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{total=calcularTotal}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>                                    
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" :disabled = "deshabilitar_boton==1"  @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>
                                <button type="button" class="btn btn-primary" :disabled = "deshabilitar_boton==1" @click="registrarIngreso()">Registrar Compra</button>
                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- Fin Detalle-->

                    <!------------------------------------------------- Ver Ingreso ------------------------------>
                    <template v-else-if="listado==2">
                    <div class="card-body">
                        <div class="form-group row border">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label for="">Proveedor</label>
                                    <p v-text="proveedor"></p>
                                </div>
                            </div>
                           
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Tipo Comprobante</label>
                                    <p v-text="tipo_comprobante"></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Serie Comprobante</label>
                                    <p v-text="serie_comprobante"></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Número Comprobante</label>
                                    <p v-text="num_comprobante"></p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row border">
                            <div class="table-responsive col-md-12">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            
                                            <th>Codigo</th>
                                            
                                            <th>Marca</th>
                                            <th>Genero</th>
                                            <th>Diseño</th>
                                            <th>Tela</th>
                                            <th>Color</th>
                                            <th>Talla</th>
                                            <th>Lote</th>

                                            <th>Nombre</th>
                                            <th>Precio_costo</th>
                                            <th>Cantidad</th>
                                            <th>Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="arrayDetalle.length">
                                        <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                           <td>{{index+1}}</td>
                                           <td v-text="detalle.codigo">
                                            </td>
                                           <td v-text="detalle.marca">
                                            </td>
                                           <td v-text="detalle.genero">
                                            </td>
                                           <td v-text="detalle.diseno">
                                            </td>
                                           <td v-text="detalle.tela">
                                            </td>
                                           <td v-text="detalle.color">
                                            </td>
                                           <td v-text="detalle.talla">
                                            </td>
                                           <td v-text="detalle.lote">
                                            </td>
                                            <td v-text="detalle.articulo">
                                            </td>
                                            <td v-text="detalle.costo">
                                            </td>
                                            <td v-text="detalle.cantidad">
                                            </td>
                                            <td>
                                                {{detalle.costo*detalle.cantidad}}
                                            </td>
                                        </tr>
                                        <!--
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Parcial:</strong></td>
                                            <td>Q {{totalParcial=(total-totalImpuesto).toFixed(2)}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                            <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                        </tr>
                                        -->
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{total}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="4">
                                                NO hay productos agregados
                                            </td>
                                        </tr>
                                    </tbody>                                    
                                </table>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" @click.prevent="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>
                                
                            </div>
                        </div>
                    </div>
                    </template>
                    <!-- fin ver ingreso -->
                </div>
                <!-- Fin ejemplo de tabla Listado -->
            </div>

   <!------------------------------------------------- Modal agregar ------------------------------>

            <div class="modal fade" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModal"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">

                   
                                   
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioA">
                                        <option value="codigo">Codigo</option>    
                                        <option value="nombre">Nombre</option>
                                        <option value="descripcion">Descripción</option>
                                        
                                        </select>
                                        <input type="text" v-model="buscarA" @keyup.enter="listarProducto(1,buscarA,criterioA,idlugar)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click.prevent="listarProducto(1,buscarA,criterioA,idlugar)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Codigo</th>
                                            <th>Nombre</th>
                                            <th>Descripcion</th>
                                            <th>Marca</th>
                                            <th>Precio Costo</th>
                                            <th>Precio Venta</th>
                                            
                                            
                                            <th>Estado</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="producto in arrayProducto" :key="producto.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>
                                           <td v-text="producto.codigo"></td>
                                            <td v-text="producto.nombre"></td>
                                            <td v-text="producto.descripcion"></td>
                                            <td v-text="producto.nombre_marca"></td>
                                            <td v-text="producto.precio_costo"></td>
                                            <td v-text="producto.precio_venta"></td>
                                            
                                            
                                            <td>
                                                <div v-if="producto.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>
                                                
                                            </td>  
                                           
                                        </tr>      
                                                                
                                    </tbody>
                                </table>
                              
                                <nav>   
                                <ul class="pagination pagination-sm m-0 float-right">
                                <li class="page-item" v-if="pagination2.current_page > 1">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarA,criterioA,idlugar)" >&laquo;</a>
                                </li>
                                <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                                    <a class="page-link" href="#" @click="cambiarPagina2(page,buscarA,criterioA,idlugar)" v-text="page"></a>
                                    </li>
                                <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                                    <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarA,criterioA,idlugar)">&raquo;</a>
                                    </li>
                                </ul>
                                </nav>
                            
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click.prevent="cerrarModal()">Cerrar</button>
                            
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->


             <!------------------------------------------------- Modal Proveedor ------------------------------>
   
            <div class="modal fade" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-primary modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" v-text="tituloModalB"></h4>
                            <button type="button" class="close" @click.prevent="cerrarModalB()" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <select class="form-control col-md-3" v-model="criterioB">
                                        <option value="nit">Nit</option>
                                        <option value="nombre">Nombre</option>
                                        
                                        </select>
                                        <input type="text" v-model="buscarB" @keyup.enter="listarProveedor(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                        <button type="submit" @click.prevent="listarProveedor(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Opciones</th>
                                            <th>Nit</th>
                                            <th>Nombre</th>
                                            <th>Direccion</th>
                                            <th>Telefono</th>
                                             <th>Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="proveedor in arrayProveedor" :key="proveedor.id">
                                            <td>
                                                <button type="button" @click.prevent="agregarProveedorModal(proveedor)" class="btn btn-success btn-sm">
                                                <i class="fas fa-check-circle"></i>
                                                </button>
                                            </td>
                                           
                                            <td v-text="proveedor.nit"></td>
                                            <td v-text="proveedor.nombre"></td>
                                            <td v-text="proveedor.direccion"></td>
                                            <td v-text="proveedor.telefono"></td>
                                            <td>
                                                <div v-if="proveedor.condicion">
                                                    <span class="badge badge-success">Activo</span>
                                                </div>
                                                <div v-else>
                                                    <span class="badge badge-danger">Desactivado</span>
                                                </div>
                                                
                                            </td>
                                        </tr>                                
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click.prevent="cerrarModalB()">Cerrar</button>
                            <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarProveedor()">Guardar</button>
                            <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarProveedor()">Actualizar</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!--Fin del modal-->

            <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modalC}" >
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header bg-primary">
              <h4 class="modal-title" v-text="tituloModalC"></h4>
              <button type="button" class="close" @click.prevent="cerrarModalC()" >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                
                  <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

                 <div class="form-group row">
                    <label for="recipient-name" class="col-form-label">Nit:</label> 
                     <input type="text" class="form-control"   v-model="nit">
                  </div>
                 
                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Nombre:</label>
                      <input type="text" class="form-control" v-model="nombre" >
                  </div>
                  
                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Direccion:</label>
                      <input type="text" class="form-control"  v-model="direccion" >
                  </div>
                  
                  <div class="form-group row">
                     <label for="message-text" class="col-form-label">Telefono:</label>
                      <input type="text" class="form-control"  v-model="telefono" >
                  </div>
                 
                  
                   <div v-show="errorProveedor" class="form-growp row  div-error">
                       <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjProveedor" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                  </form>
            </div>
            <div class="modal-footer ">
              <button type="button" class="btn btn-default"  @click.prevent="cerrarModalC()">Close</button>
              <button type="button"  :disabled = "deshabilitar_boton==1"  class="btn btn-primary" @click="registrarProveedor()">Guardar</button>
               
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->
        </main>
</template>

<script>
    
    export default {
        data (){
            return {
                ingreso_id: 0,
                idproveedor:0,
                idlugar : 0,
                idlugar2 : 1,
                nit: '',
                proveedor:'',
                nombre : '',
                nombrep : '',
                direccion : '',
                telefono : '',
                lugar: '',
                tipo_comprobante : 'TICKET',
                serie_comprobante : '',
                num_comprobante : '',
                impuesto: 0.0,
                deshabilitar_boton:0,
                total:0.0,
                totalImpuesto: 0.0,
                
                totalParcial: 0.0,
                arrayIngreso : [],
                arrayProveedor: [],
                arrayDetalle : [],
                 arrayLugar :[],
                 arrayLugar2 :[],
                 arrayAnularC: [],
                listado:1,
                modal : 0,
                modalB : 0,
                modalC : 0,
                tituloModal : '',
                tituloModalB : '',
                tituloModalC : '',
                tipoAccion : 0,
                errorIngreso : 0,
                errorProveedor : 0,
                errorMostrarMsjIngreso : [],
                errorMostrarMsjProveedor : [],

                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset : 3,
                
                pagination2 : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,
                },
                offset2 : 3,
                criterio : 'num_comprobante',
                buscar : '',
                criterioA:'nombre',
                buscarA: '',
                criterioB:'nombre',
                buscarB: '',
                arrayProducto: [],
                idproducto: 0,
               // codigo: '',
                producto: '',
                precio: 0,
                cantidad:0,
                stock:0
            }
        },
        components: {
           
        },
        computed:{
            isActived: function(){
                return this.pagination.current_page;
            },

            isActived2: function(){
                return this.pagination2.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }
                
                var from = this.pagination.current_page - this.offset; 
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2); 
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }  

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;
            },

            pagesNumber2: function() {
                if(!this.pagination2.to) {
                    return [];
                }
                
                var from = this.pagination2.current_page - this.offset2; 
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset2 * 2); 
                if(to >= this.pagination2.last_page){
                    to = this.pagination2.last_page;
                }  

                var pagesArray2 = [];
                while(from <= to) {
                    pagesArray2.push(from);
                    from++;
                }
                return pagesArray2;
            },
            calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){
                    resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            }
        },
        methods : {


      /**************************************************************************************** */      
            listarIngreso (page,buscar,criterio,idlugar2){
                let me=this;
                var url= '/ingreso?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio + '&idlugar2='+ idlugar2;
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayIngreso = respuesta.compras.data;
                    me.pagination= respuesta.pagination;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

       /**************************************************************************************** */       
            buscarProveedor(){
                let me=this;
              

                var url= '/proveedor/selectProveedor?filtro='+search;
                axios.get(url).then(function (response) {
                    let respuesta = response.data;
                    me.arrayProveedor=respuesta.proveedores;
                   
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

        /**************************************************************************************** */      
            getDatosProveedor(val1){
                let me = this;
                me.loading = true;
                me.idproveedor = val1.id;
            },

         /**************************************************************************************** */     
          
          buscarProducto(){
                let me=this;
                var url= '/producto/buscarProducto?filtro=' + me.codigo;

                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos;

                    if (me.arrayProducto.length>0){
                        me.producto=me.arrayProducto[0]['codigo'];
                        me.idproducto=me.arrayProducto[0]['id'];
                    }
                    else{
                        me.producto='No existe producto';
                        me.idproducto=0;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            selectLugar2(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar2 = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            

          /**************************************************************************************** */    

            cambiarPagina(page,buscar,criterio,idlugar2){
                let me = this;
                //Actualiza la página actual
                me.pagination.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarIngreso(page,buscar,criterio,idlugar2);
            },

            cambiarPagina2(page,buscarA,criterioA,idlugar){
                let me = this;
                //Actualiza la página actual
                me.pagination2.current_page = page;
                //Envia la petición para visualizar la data de esa página
                me.listarProducto(page,buscarA,criterioA,idlugar);
            },

          /**************************************************************************************** */    
            encuentra(id){
                var sw=0;
                for(var i=0;i<this.arrayDetalle.length;i++){
                    if(this.arrayDetalle[i].idproducto==id){
                        sw=true;
                    }
                }
                return sw;
            },

          /**************************************************************************************** */    
            eliminarDetalle(index){
                let me = this;
                me.arrayDetalle.splice(index, 1);
            },
           /**************************************************************************************** */   
            agregarDetalle(){

                let me=this;
                if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
                }
                else{
                    if(me.encuentra(me.idproducto)){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: me.idproducto,
                            idlugar: me.idlugar,
                            codigo: me.codigo,
                            producto: me.producto,
                            cantidad: me.cantidad,
                            costo: me.costo,
                            precio: me.precio
                        });
                        me.codigo="";
                        me.idproducto=0;
                        me.idlugar=0;
                        me.codigo="";
                        me.producto="";
                        me.cantidad=0;
                        me.costo=0; 
                        me.precio=0; 
                    }
                    
                }


            },

             /**************************************************************************************** */ 
            agregarDetalleModal(data =[]){
                
                let me=this;
                if(me.encuentra(data['id'])){
                        swal.fire({
                            type: 'error',
                            title: 'Error...',
                            text: 'Ese producto ya se encuentra agregado!',
                            })
                    }
                    else{
                       me.arrayDetalle.push({
                            idproducto: data['id'],
                            codigo: data['codigo'],
                            producto: data['nombre'],
                            cantidad: 1,
                            costo: data['precio_costo'],
                            precio: data['precio_venta'],
                            precio: data['stock']

                        }); 
                    }
            },

            /**************************************************************************************** */ 
            agregarProveedorModal(data =[]){
                
                
                let me=this;


                                me.idproveedor=data['id'];
                                me.nit = data['nit'];
                                me.nombrep = data['nombre'];
                                me.direccion = data['direccion'];
                                me.telefono = data['telefono'];
                this.cerrarModalB();
                    
            },

             registrarProveedor(){


           
                if (this.validarProveedor()){
                    return;
                }

                 
                let me = this;
                me.deshabilitar_boton=1;
                axios.post('/proveedor/registrar',{
                  
                    
                    'nit': this.nit,
                    'nombre': this.nombre,
                    'direccion': this.direccion,
                    'telefono' : this.telefono
                    
                }).then(function (response) {
                  
                    me.cerrarModalC();
                    me.deshabilitar_boton=0;
                   me.listarIngreso(1,'','tipo_comprobante',me.idlugar2);
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });
                }).catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */ 
            listarProducto (page,buscarA,criterioA,idlugar){
                let me=this;
                var url= '/producto/listarProducto?page=' + page + '&buscar='+ buscarA + '&criterio='+ criterioA+ '&idlugar='+ idlugar;
                axios.get(url).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayProducto = respuesta.productos.data;
                    me.pagination2= respuesta.pagination2;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
         

                 /**************************************************************************************** */ 
            listarProveedor (buscarB,criterioB){
                 let me=this;
                var url= '/proveedor/listarProveedor?buscarB='+ buscarB + '&criterioB='+ criterioB;
               
                axios.get(url).then(function (response) {
                    var respuesta= response.data;
                    me.arrayProveedor = respuesta.proveedor.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
             /**************************************************************************************** */ 

            registrarIngreso(){
                if (this.validarIngreso()){
                    return;
                }
                
                 let me = this;

                 
               
            
                me.deshabilitar_boton=1;

                axios.post('/ingreso/registrar',{
                    'idproveedor': this.idproveedor,
                    'tipo_comprobante': this.tipo_comprobante,
                    'serie_comprobante' : this.serie_comprobante,
                    'num_comprobante' : this.num_comprobante,
                    'idlugar' : this.idlugar,
                    //'impuesto' : this.impuesto,
                    'total' : this.total,
                    'data': this.arrayDetalle
                    

                }).then(function (response) {
                   //console.log(response);
                    me.listado=1;
                   // me.listarIngreso(1,'','num_comprobante',me.idlugar2);

                    me.idproveedor=0;
                    me.tipo_comprobante='TICKET';
                    me.serie_comprobante='';
                    me.num_comprobante='';
                    //me.impuesto=0.18;
                    me.total=0.0;
                    me.idproducto=0;
                    me.codigo='';
                    me.producto='';
                    me.cantidad=0;
                    me.costo=0;
                    me.precio=0;

                    me.arrayDetalle=[];

                     me.cerrarModalC();
                    me.listarIngreso(1,'','tipo_comprobante',me.idlugar2);
                
                     Swal.fire({
                          position: 'top',
                          icon: 'success',
                          title: 'Datos Agregados Con Exito!',
                          showConfirmButton: false,
                          timer: 1500
                        });

                         me.deshabilitar_boton=0;

                }).catch(function (error) {
                    console.log(error);
                });
             
            },

             /**************************************************************************************** */ 
            validarIngreso(){
                this.errorIngreso=0;
                this.errorMostrarMsjIngreso =[];

                if (this.idproveedor==0) this.errorMostrarMsjIngreso.push("Seleccione un Proveedor");
                if (this.tipo_comprobante==0) this.errorMostrarMsjIngreso.push("Seleccione el comprobante");
                if (!this.num_comprobante) this.errorMostrarMsjIngreso.push("Ingrese el número de comprobante");
                //if (!this.impuesto) this.errorMostrarMsjIngreso.push("Ingrese el impuesto de compra");
                if (this.arrayDetalle.length<=0) this.errorMostrarMsjIngreso.push("Ingrese detalles");

                if (this.errorMostrarMsjIngreso.length) this.errorIngreso = 1;

                return this.errorIngreso;
            },

             /**************************************************************************************** */ 

              validarProveedor(){


               this.errorProveedor=0;
                this.errorMostrarMsjProveedor  =[];

                
                if (!this.nit){
                  this.errorMostrarMsjProveedor .push("El nit no puede estar vacío.");
                
                }
                if (!this.nombre){
                   this.errorMostrarMsjProveedor.push("El nombre no puede estar vacio.");
                  
                }
                /*
                if (!this.direccion){
                  this.errorMostrarMsjProveedor.push("La Direccion no puede estar vacío.");
               
                }

                if (!this.telefono){
                  this.errorMostrarMsjProveedor.push("El telefono no puede estar vacío.");
                
                }
                */
                

                if (this.errorMostrarMsjProveedor.length) this.errorProveedor = 1;
              
                return this.errorProveedor;
              
            },
            mostrarDetalle(){

                let me=this;
                me.listado=0;

                me.idproveedor=0;
                me.tipo_comprobante='TICKET';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;

                me.total=0.0;
                me.idproducto=0;
                me.codigo='';
                me.producto='';
                me.cantidad=0;
                me.costo=0;
                me.precio=0;
                me.arrayDetalle=[];
            },

             /**************************************************************************************** */ 
            ocultarDetalle(){
                this.listado=1;
            },

             /**************************************************************************************** */ 
            verIngreso(id){
                
                let me=this;
                me.listado=2;
               // console.log(id);
                //Obtener los datos del ingreso
                var arrayIngresoT=[];
                var url= '/ingreso/obtenerCabecera?id=' + id;
                
                axios.get(url).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    arrayIngresoT = respuesta.compra;

                    me.proveedor = arrayIngresoT[0]['nombre'];
                    me.tipo_comprobante=arrayIngresoT[0]['tipo_comprobante'];
                    me.serie_comprobante=arrayIngresoT[0]['serie_comprobante'];
                    me.num_comprobante=arrayIngresoT[0]['num_comprobante'];
                   
                    //me.impuesto=arrayIngresoT[0]['impuesto'];
                    me.total=arrayIngresoT[0]['total'];
                })
                .catch(function (error) {
                    console.log(error);
                });

                //Obtener los datos de los detalles 
                var urld= '/ingreso/obtenerDetalles?id=' + id;
                
                axios.get(urld).then(function (response) {
                    //console.log(response);
                    var respuesta= response.data;
                    me.arrayDetalle = respuesta.detalles;
                })
                .catch(function (error) {
                    console.log(error);
                });               
            },

             /**************************************************************************************** */ 
            cerrarModal(){
                this.modal=0;
                this.tituloModal='';
                this.desbloquear=0;
                this.deshabilitar_boton=0;
            }, 

             /**************************************************************************************** */ 
            abrirModal(){               
                this.arrayProducto=[];
                this.modal = 1;
                this.tituloModal = 'Seleccione uno o varios productos';
            },

             /**************************************************************************************** */ 
             /*
            desactivarIngreso(id){
               swal.fire({
                title: 'Esta seguro de anular este ingreso?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
                }).then((result) => {
                if (result.value) {
                    let me = this;

                    axios.put('/ingreso/desactivar',{
                        'id': id
                    }).then(function (response) {
                        me.listarIngreso(1,'','num_comprobante');
                        swal.fire(
                        'Anulado!',
                        'El ingreso ha sido anulado con éxito.',
                        'success'
                        )
                    }).catch(function (error) {
                        console.log(error);
                    });
                    
                    
                } else if (
                    // Read more about handling dismissals
                    result.dismiss === swal.DismissReason.cancel
                ) {
                    
                }
                }) 
            },
*/
             desactivarIngreso : function(id,num_comprobante,total){


                 let me = this;
                 let lugar2 = this.idlugar2;
                 
                 

                 var urld= '/ingreso/obtenerDetalles?id=' + id +'&num_comprobante='+num_comprobante;
                
                axios.get(urld).then(function (response) {
                   // console.log(response);
                    var respuesta= response.data;
                    me.arrayAnularC = respuesta.detalles;
                    
                   
                  
                })
                .catch(function (error) {
                    console.log(error);
                });  
             
             this.detallelist(id,lugar2,num_comprobante,total);
                },

         /**************************************************************************************** */ 

          detallelist(id,lugar2,num_comprobante,total){

              Swal.fire({
                title: 'Esta seguro/a que desea Anular esta Compra?',
                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar'
              }).then((result) => {

                  
                if (result.value) {
                  let me = this;
                 /* 
                  console.log(total);
                      console.log(num_comprobante);
                       console.log(lugar2);
                        console.log(id);
                        console.log(this.arrayAnularC);
                 */
                axios.put('/ingreso/desactivar',{
                          'id': id,
                          'num_comprobante':num_comprobante,
                          'total':total,
                          'lugar2': lugar2,
                          'data': this.arrayAnularC
                          

                        }).then(function(response){
                          //  console.log(response);
                          me.listarIngreso(1,'','fecha_hora',lugar2);
                          me.arrayAnularC=[];
                          Swal.fire(
                            'Anulado!',
                            'El registro a sido desactivado con exito.',
                            'success'
                          )
                        }).catch(function(error){
                          console.log(error);
                        });
                    
                  //console.log(this.arrayAnularC);
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                 
                }
              })

          },
            
                 cerrarModalB(){
                this.modalB=0;
                this.tituloModalB='';
            }, 
            abrirModalB(){               
                this.arrayProveedor=[];
                this.modalB = 1;
                this.tituloModalB = 'Seleccione el proveedor';
            },

             cerrarModalC(){
                this.modalC=0;
                this.tituloModalC='';
                this.nit='';
                this.nombre='';
                this.direccion='';
                this.telefono='';
                this.errorProveedor=0;
                this.val1=0;
                this.val2=0;
                this.val3=0;
                this.val4=0;
            }, 

             abrirModalC(){               
                
                this.modalC = 1;
                this.tituloModalC = 'Registrar Proveedor';

            },

             mostrarDetalle(){
                let me=this;
                me.listado=0;

                me.idproveedor=0;
                me.tipo_comprobante='TICKET';
                me.serie_comprobante='';
                me.num_comprobante='';
                //me.impuesto=0.18;
                me.total=0.0;
                me.idproducto=0;
                me.codigo='';
                me.producto='';
                me.cantidad=0;
                me.costo=0;
                me.precio=0;
                me.arrayDetalle=[];
            },
            ocultarDetalle(){
                this.listado=1;
            },

        },
        mounted() {
            this.selectLugar2();
            this.listarIngreso(1,this.buscar,this.criterio,this.idlugar2);
            this.selectLugar();
            
        }
    }
</script>
<style>    
    .modal-content{
        width: 100% !important;
        position: absolute !important;
    }
    .mostrar{
        display: list-item !important;
        opacity: 1 !important;
        position: absolute !important;
        background-color: #3c29297a !important;
    }
    .div-error{
        display: flex;
        justify-content: center;
    }
    .text-error{
        color: red !important;
        font-weight: bold;
    }
    @media (min-width: 600px) {
        .btnagregar {
            margin-top: 2rem;
        }
    }

</style>
