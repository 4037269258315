<template>


    <!-- Main content -->
 <div class="content">







 <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">


            <h1 class="m-0 text-dark">Kardex_Gifts</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">

          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>


    <div class="container-fluid">

      <div class="row">

<div class="col-12">


            <div class="card ">
              <div class="card-header">
                <form action="">
                  <!--
                 <button type="button" @click="abrirModal('producto','registrar')" class="btn btn-success mb-2"><i class="fas fa-plus-square"></i>&nbsp;Nuevo</button>&nbsp;
                 -->

                 <div v-if="contarAlerta > 0">
               <button type="button" class="btn btn-danger" @click="abrirModalAlarma('producto','alarma')" ><i class="fas fa-bell"></i> {{contarAlerta}}</button>
            </div>
                  <div class="form-group">

                     <label for="FormControlinput1">De: </label>
                       <input id="FormControlinput1" type="date" class="form-control" v-model="de" >&nbsp;



                        <label for="FormControlinput2">A: </label>
                       <input id="FormControlinput2" type="date" class="form-control" v-model="a" >&nbsp;
                     </div>






                </form>

                 <div class="form-group row">
                   <div class="col-md-6">
                     <div class="input-group">
                       <select name="" id="" class="form-control col-md-3" v-model="criterio">
                         <option value="codigo">Codigo_GiftCard</option>
                         <option value="num">Codigo_Venta</option>
                         <option value="pos">Codigo_VentaPOS</option>

                       </select>
                       <input type="text" class="form-control" placeholder="Buscar" v-model="codigo" @keyup.enter="listarReporte(idlugar,de,a,codigo,criterio)">
                       <button type="submit" class="btn btn-primary" @click.prevent="listarReporte(de,a,codigo,criterio)"><i class="fa fa-search"></i>&nbsp;Buscar</button>
                     </div>
                       <div v-show="errorReporte" class="form-growp row  div-error">
                      <div class="text-center text-error">
                                        <div v-for="error in errorMostrarMsjReporte" :key="error" v-text="error">

                                        </div>
                                    </div>
                  </div>
                   </div>
                 </div>

               </div>


              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <table  class="table table-bordered table-strined table-light">
                  <thead class="thead-dark">
                  <tr>
                    <th>No</th>
                    <th>Numero</th>
                    <th>Fecha_hora</th>
                    <th>Codigo</th>
                    <th>Tipo_Movimiento</th>
                    <th>Monto</th>
                     <th>EstadoVenta</th>
                     <th>Lugar</th>





                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(reporte,index) in arrayReporte" :key="reporte.id" >
                    <td>
                      {{index+1}}
                    </td>

                               <td v-text="reporte.num"></td>
                                <td v-text="reporte.fecha"></td>
                                <td v-text="reporte.codigo"></td>
                                <td v-text="reporte.tipo"></td>
                                <td v-text="reporte.monto"></td>
                                <td v-text="reporte.estado"></td>
                                <td v-text="reporte.nombre"></td>








                  </tr>


                  </tbody>
                  <tfoot>

                  </tfoot>
                </table>
                <div class="card-footer clearfix">
                 <nav>
                <ul class="pagination pagination-sm m-0 float-right">
                  <li class="page-item" v-if="pagination.current_page > 1">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio,idlugar)" >&laquo;</a>
                 </li>
                  <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                    <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio,idlugar)" v-text="page"></a>
                    </li>
                  <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                    <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio,idlugar)">&raquo;</a>
                    </li>
                </ul>
                </nav>
              </div>
            </div>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>









      <!-- /.row -->
    </div><!-- /.container-fluid -->

  <!-- /.content -->
</template>

<script>




export default {

       data (){
            return {
                producto_id: 0,
                 de:"",
                a:"",
                errorReporte : 0,
                errorMostrarMsjReporte : [],

                arrayReporte : [],
                 idcategoria : 0,
                 idlugar : 0,
                nombre_categoria : '',
                nombre_lugar : '',
                codigo : '',
                nombre : '',
                lugar: '',
                precio_costo : 0,
                precio_venta : 0,
                stock : 0,
                descripcion : '',
                minimo : 0,
                arrayProducto : [],
                arrayCategoria :[],
                arrayLugar :[],
                modal : 0,
                modal2 : 0,
                deshabilitar_boton:0,
                tituloModal : '',
                tituloModal2 : '',
                tipoAccion : 0,
                errorProducto : 0,
                errorMostrarMsjProducto : [],
                'mensaje':'',
                'val1':0,
                    'val2':0,
                    'val3':0,
                    'val4':0,
                    'val5':0,
                    'val6':0,
                pagination : {
                    'total' : 0,
                    'current_page' : 0,
                    'per_page' : 0,
                    'last_page' : 0,
                    'from' : 0,
                    'to' : 0,

                },
                offset : 3,
                criterio : 'codigo',
                buscar : ''
            }
        },




        computed:{



            isActived: function(){
                return this.pagination.current_page;
            },
            //Calcula los elementos de la paginación
            pagesNumber: function() {
                if(!this.pagination.to) {
                    return [];
                }

                var from = this.pagination.current_page - this.offset;
                if(from < 1) {
                    from = 1;
                }

                var to = from + (this.offset * 2);
                if(to >= this.pagination.last_page){
                    to = this.pagination.last_page;
                }

                var pagesArray = [];
                while(from <= to) {
                    pagesArray.push(from);
                    from++;
                }
                return pagesArray;

            },

            contarAlerta: function(){

              var resultado=0;
                for(var i=0;i<this.arrayProducto.length;i++){

                    if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

                      resultado +=1;
                    }


                }
                return resultado;
            }
        },
        methods : {


            selectLugar(){
                let me=this;
                var url= '/lugar/selectLugar';
                axios.get(url).then(function (response) {
                   console.log(response);
                    var respuesta= response.data;
                    me.arrayLugar = respuesta.lugares;
                })
                .catch(function (error) {
                    console.log(error);
                });
            },



            listarReporte: async function(de,a,codigo,criterio){



              if (this.validarReporte()){
                    return;
                }

                let me=this;
                 var url= '/kardexgift?de=' + de + '&a='+ a + '&codigo='+ codigo + '&criterio='+ criterio  ;
                  axios.get(url).then(function (response) {
                  //console.log(response);

                    var respuesta= response.data;
                    me.arrayReporte = respuesta.reporte;





                })
                .catch(function (error) {
                    console.log(error);
                });


            },


             validarReporte(){



                this.errorReporte=0;
                this.errorMostrarMsjReporte  =[];

                if (this.de==""){
                  this.errorMostrarMsjReporte.push("EL Rangos de Fecha esta Vacio.");

                }

                if (this.a==""){
                   this.errorMostrarMsjReporte.push("Uno de los Rangos de Fecha esta Vacio.");

                }



                 if (this.codigo==""){
                  this.errorMostrarMsjReporte.push("El codigo no puede estar vacio.");

                }



                if (this.errorMostrarMsjReporte.length) this.errorReporte = 1;
                    return this.errorReporte;


            },


        },
        mounted() {
            //this.listarProducto(1,this.buscar,this.criterio,this.tienda);
            this.selectLugar();
        }
    }
</script>
<style>
.modal-content{
    width: 100% !important;
    position: absolute !important;
}

.mostrar{
    display: list-item !important;
    opacity: 1 !important;
    position: absolute !important;
    background-color: #3c29297a !important;

}

.diverror{
  display: flex;
  justify-content: center;
}

.text-error{
  color: red;
  font-weight: bold;
}

</style>
