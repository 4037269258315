<template>
    <main class="main">
    <!-- Content Header (Page header) -->
    <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
        <div class="col-sm-6">

            <h1 class="m-0 text-dark">Guias</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">

        </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
    </div>
    <div class="container-fluid">
        <!-- Ejemplo de tabla Listado -->
        <div class="card">


            <div class="card-header">
                <i class="fa fa-align-justify"></i> Guias
                <button type="button" @click.prevent="mostrarDetalle()" class="btn btn-success">
                    <i class="fas fa-plus-square"></i>&nbsp;Nuevo
                </button>

                <!--
                <button type="button" @click.prevent="validarUser()" class="btn btn-success">
                    <i class="fas fa-plus-square"></i>&nbsp;Validar
                </button>
               -->
            </div>
            <!------------------------------------------------- Listado Principal ------------------------------>
            <template v-if="listado==1">
            <div class="card-body">
                <div class="form-group row">
                    <div class="col-md-6">
                        

                        <div class="input-group">

                            <select class="form-control col-md-3" v-model="criterio">
                              <option value="tipo_comprobante">Tipo Comprobante</option>
                              <option value="num_comprobante">Número Comprobante</option>
                              <option value="fecha_hora">Fecha-Hora</option>
                            </select>
                            <input type="text" v-model="buscar" @keyup.enter="listarTracking(1,buscar,criterio)" class="form-control" placeholder="Texto a buscar">
                            <button type="submit" @click="listarTracking(1,buscar,criterio)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Opciones</th>
                                <th>Usuario</th>
                                <th>Cliente</th>
                                
                                <th>Tipo Comprobante</th>
                                
                                <th>Número Comprobante</th>
                                <th>Fecha Hora</th>
                                <th>Envio</th>
                                <th>Total Guia</th>
                                <th>Total_neto</th>

                                

                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tracking,index) in arrayTracking" :key="tracking.id">
                                <td>
                                    {{(index+1)}}
                                </td>
                                <td>
                                    <button type="button" @click="verTracking(tracking.id)" class="btn btn-success btn-sm">
                                    <i class="fas fa-eye"></i>
                                    </button> &nbsp;
                                    <button type="button" @click="pdfTracking(tracking.id,tracking.num_comprobante)" class="btn btn-info btn-sm">
                                    <i class="fas fa-file-pdf"></i>
                                    </button> &nbsp;
                                    
                                    <!--
                                    <template v-if='tracking.estado=="Registrado"'>
                                        <button type="button" class="btn btn-danger btn-sm" @click="desactivarTracking(tracking.id,tracking.num_comprobante,tracking.total)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>

                                    </template>
                                    -->
                                </td>
                                <td v-text="tracking.usuario"></td>
                                <td v-text="tracking.nombre"></td>
                                
                                <td v-text="tracking.tipo_comprobante"></td>
                               

                                <td v-text="tracking.num_comprobante"></td>
                                <td v-text="tracking.fecha_hora"></td>
                                <td v-text="tracking.envio"></td>
                                <td id="total" v-text="tracking.total"></td>
                                <td id="ntotal" v-text="tracking.total_neto"></td>

                                

                            <td>
                              
                              <div v-if="tracking.estado=='Anulada'">
                                   <span class="badge badge-danger"> Anulada </span>
                              </div>

                              <div v-if="tracking.estado=='Registrado'">
                                   <span class="badge badge-success"> Registrada </span>
                              </div>

                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <nav>
                    <ul class="pagination">
                        <li class="page-item" v-if="pagination.current_page > 1">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page - 1,buscar,criterio)">Ant</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
                        </li>
                        <li class="page-item" v-if="pagination.current_page < pagination.last_page">
                            <a class="page-link" href="#" @click.prevent="cambiarPagina(pagination.current_page + 1,buscar,criterio)">Sig</a>
                        </li>
                    </ul>
                </nav>
            </div>
            </template>
            <!--Fin Listado-->

        <!------------------------------------------------- Detalle ------------------------------>
            <template v-else-if="listado==0">
            <div class="card-body">
                <div class="form-group row border">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Cliente <span style="color:red;" v-show="idcliente==0">(*Seleccione)</span></label>
                            <div class="form-inline">
                                <button @click.prevent="abrirModalC()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                                <button @click.prevent="abrirModalB()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                <input type="text"  class="form-control " v-on:keyup.enter="buscarBcliente()" v-model="bcliente">
                                <div>

                                     <input type="text"   readonly class="form-control limpiar" v-model="nit" placeholder="Nit">
                                <input type="text"   readonly class="form-control limpiar" v-model="nombrep" placeholder="Nombre">
                                <input type="text"   readonly class="form-control limpiar" v-model="direccion" placeholder="Direccion">
                                <input type="text"   readonly class="form-control limpiar" v-model="telefono" placeholder="Telefono">
                                </div>

                            </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo Comprobante(*)</label>
                            <select class="form-control" v-model="tipo_comprobante">
                                <option value="0">Seleccione</option>
                                <option value="COD">COD</option>
                                <option value="COLLET">COLLET</option>
                                <option value="Manual">Manual</option>
                            </select>
                        </div>
                    </div>

                  


                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante(*)(Guia)</label>
                            <input type="text" class="form-control" v-model="num_comprobante" placeholder="000xx">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="">Envio(*)</label>
                            <input type="text" class="form-control" v-model.number="envio">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div v-show="errorTracking" class="form-group row div-error">
                            <div class="text-center text-error">
                                <div v-for="error in errorMostrarMsjTracking" :key="error" v-text="error">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="form-group row border">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label>Producto <span style="color:red;" v-show="idproducto==0">(*Seleccione)</span></label>
                            <div class="form-inline">
                                <button @click.prevent="abrirModal()" class="btn btn-primary"><i class="fas fa-search-plus"></i></button>
                                 <input  v-on:keyup.enter="buscarProducto2()" v-model="codigo" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                            </div>
                           

                        </div>
                    </div>

                </div>
                <div class="col-md-4">
                        <div class="form-group">
                            <label>Total Articulos</label>
                            <p>{{totalArticulo}}</p>
                        </div>
                    </div>

                <div class="form-group row border">
                    <div class="table-responsive-md col-md-12">
                        <table  class="table table-bordered table-striped ">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Opciones</th>
                                    <th>Codigo</th>
                                    <th>Producto</th>
                                  
                                    <th>PrecioNormal</th>
                                    <th>PrecioEspecial</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    
                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>
                                        {{(index+1)}}
                                    </td>
                                    <td>
                                        <button @click="eliminarDetalle(index)" type="button" class="btn btn-danger btn-sm">
                                            <i class="fas fa-times-circle"></i>
                                        </button>
                                    </td>
                                    <td v-text="detalle.codigo">
                                    </td>
                                    <td v-text="detalle.producto">
                                    </td>
                                    <!--
                                     <td>
                                        <input style="width: 100px" disabled v-model="detalle.costo" type="number" value="4" class="form-control" >
                                    </td>
                                    -->

                                    <td>
                                        {{detalle.precionormal}}
                                    </td>

                                    <td>

                                    <template v-if="detalle.cantidad==1">
                                                 {{detalle.precio=detalle.precio01}}

                                                </template>

                                                <template v-if="detalle.cantidad==2">

                                                 {{detalle.precio=detalle.precio0}}

                                                </template>

                                                  <template v-if="detalle.cantidad>=3">

                                                  {{detalle.precio=detalle.precio1}}



                                                </template>
                                            </td>
                                            <td>
                                                 <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                                <input v-model.number="detalle.cantidad"  type="number" class="form-control">
                                            </td>
                                           
                                            <td>
                                               <!--
                                                
                                              {{detalle.descuento=0}}
                                              -->

                                                <template v-if="detalle.cantidad ==1 ">
                                                   {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio01*detalle.cantidad}}
                                                </template>
                                                <template v-if="detalle.cantidad==2">
                                                  {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio0*detalle.cantidad}}

                                                </template>
                                                 <template v-if="detalle.cantidad>=3">
                                                 {{detalle.descuento=detalle.precionormal*detalle.cantidad-detalle.precio1*detalle.cantidad}}
                                               </template>

                                            </td>
                                            <td>
                                                {{(detalle.precio*detalle.cantidad).toFixed(2)}}
                                            </td>
                                            
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Guia:</strong></td>
                                            <td>Q {{totalG=totalMasEnvio}}</td>
                                        </tr>

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{totalN=calcularTotal}}</td>
                                        </tr>

                                       <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>


                                       
                                    </tbody>

                                    <!--
                                    <td>
                                         <span style="color:red;" v-show="(detalle.stock < detalle.cantidad)">Cantidad Mayor al Stock</span>
                                        <input v-model.number="detalle.cantidad"  type="number" value="2" class="form-control">
                                    </td>
                                    <td>
                                        <span style="color:red;" v-show="detalle.descuento>(detalle.precio*detalle.cantidad)">Descuento superior</span>
                                        <input v-model="detalle.descuento" type="number" class="form-control">
                                    </td>
                                    <td>
                                        {{(detalle.precio*detalle.cantidad-detalle.descuento).toFixed(2)}}
                                    </td>
                                     <td id="totalc2">
                                        {{(detalle.costo*detalle.cantidad).toFixed(2)}}
                                    </td>
                                     <td id="totalu2">
                                        {{((detalle.precio*detalle.cantidad-detalle.descuento)-(detalle.costo*detalle.cantidad)).toFixed(2)}}
                                    </td>
                                </tr>

                                  <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total Parcial:</strong></td>
                                    <td>Q {{totalParcial=calcularTotal}}</td>
                                </tr>
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total Envio:</strong></td>
                                    <td>Q {{totalEnvio=parseFloat(this.envio).toFixed(2)}}</td>
                                </tr>

                                <tr style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>Total Neto:</strong></td>
                                    <td>Q {{total= (parseFloat(this.calcularTotal) + parseFloat(this.envio)).toFixed(2)}}</td>
                                </tr>
                                <tr  style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>TotalCosto:</strong></td>
                                    <td>Q {{totalc=calcularTotalc}}</td>
                                </tr>
                                <tr  style="background-color: #CEECF5;">
                                    <td colspan="4" align="right"><strong>TotalUtilidad:</strong></td>
                                    <td>Q {{totalu=calcularTotalu}}</td>
                                </tr>
                            </tbody>
                            -->


                            
                            <tbody v-else>
                                <tr>
                                    <td colspan="5">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>



                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        Total: Q{{totalG}}<br>


                             <button type="button"  class="btn btn-primary" :disabled = "deshabilitar_boton==1"  @click="registrarTracking()">Registrar Tracking</button>

                             <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>


                    </div>
                </div>
            </div>
            </template>
            <!-- Fin Detalle-->

            <!------------------------------------------------- Ver Tracking_detalle ------------------------------>
            <template v-else-if="listado==2">
            <div class="card-body">
                <div class="form-group row border">
                    <div class="col-md-9">
                        <div class="form-group">
                            <label for="">Cliente</label>
                            <p v-text="cliente"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo Comprobante</label>
                            <p v-text="tipo_comprobante"></p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Número Comprobante </label>
                            <p id="numTracking" v-text="num_comprobante"></p>

                        </div>
                    </div>
                     <div class="col-md-4">
                        <div class="form-group">
                            <label>Envio **</label>
                            <p v-text="envio"></p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Total Articulos</label>
                            <p>{{totalArticulo}}</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Estado</label>
                            <p id="estado" v-text="estado"></p>

                        </div>
                    </div>
                </div>
                <div class="form-group row border">
                    <div class="table-responsive col-md-12">

                        <table class="table table-bordered table-striped table-sm">

                            <thead>
                                <tr>



                                    <th>No</th>
                                    <th>Codigo</th>

                                    <th>Genero</th>

                                    <th>Producto</th>
                              
                                    <th>Precio_normal</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Descuento</th>
                                    <th>Subtotal</th>
                                    


                                </tr>
                            </thead>
                            <tbody v-if="arrayDetalle.length">
                                <tr v-for="(detalle,index) in arrayDetalle" :key="detalle.id">
                                    <td>
                                        {{(index+1)}}
                                    </td>

                                    <td v-text="detalle.codigo">
                                    </td>

                                   <td v-text="detalle.genero">
                                    </td>


                                    <td v-text="detalle.articulo">
                                    </td>

                                    
                                    <td v-text="detalle.precionormal">
                                    </td>
                                    <td v-text="detalle.precio">
                                    </td>
                                    <td v-text="detalle.cantidad">
                                    </td>
                                    <td v-text="detalle.descuento">
                                    </td>
                                    <td>
                                        {{detalle.precio*detalle.cantidad}}
                                    </td>
                                   
                                </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>     
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{calcularTotal}}</td>
                                        </tr>
                                        
                                        

                               
                                <!--
                                <tr style="background-color: #CEECF5;">
                                    <td colspan="3" align="right"><strong>Total Impuesto:</strong></td>
                                    <td>Q {{totalImpuesto=((total*impuesto)).toFixed(2)}}</td>
                                </tr>
                            -->
                             <!--
                            

                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Neto:</strong></td>
                                            <td>Q {{calcularTotalN}}</td>
                                        </tr>

                                       <tr style="background-color: #CEECF5;">
                                            <td colspan="3" align="right"><strong>Total Descuento:</strong></td>
                                            <td>Q {{(totalDescuento).toFixed(2)}}</td>
                                        </tr>


                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalCosto:</strong></td>
                                            <td>Q {{totalc=calcularTotalc}}</td>
                                        </tr>
                                        <tr style="background-color: #CEECF5;">
                                            <td colspan="5" align="right"><strong>TotalUtilidad:</strong></td>
                                            <td>Q {{totalu=calcularTotalu}}</td>
                                        </tr>
                              -->


                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4">
                                        NO hay productos agregados
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <button type="button" @click="ocultarDetalle()" class="btn btn-secondary">Cerrar</button>

                    </div>
                </div>
            </div>
            </template>
            <!-- fin ver tracking -->
        </div>
        <!-- Fin ejemplo de tabla Listado -->
    </div>

<!------------------------------------------------- Modal agregar ------------------------------>

    <div class="modal fade p-5" tabindex="-1" :class="{'mostrar' : modal}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" v-text="tituloModal"></h4>
                    <button type="button" class="close" @click.prevent="cerrarModal()" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-md-6">

                            <div class="input-group">

                                <select class="form-control col-md-3" v-model="criterioA">
                                <option value="codigo">Codigo</option>
                                <option value="nombre">Nombre</option>
                                <option value="descripcion">Descripción</option>
                                </select>
                                <input type="text" v-model="buscarA" @keyup.enter="listarProducto(1,buscarA,criterioA)" class="form-control" placeholder="Texto a buscar">
                                <button type="submit" @click="listarProducto(1,buscarA,criterioA)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Opciones</th>

                                    <th>Codigo</th>
                                    <th>Nombre</th>
                                    <th>Descripcion</th>
                                    
                                    <th>Precio_venta</th>
                                   
                                    <th>Stock</th>
                                    <th>Estado</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="producto in arrayProducto" :key="producto.id">
                                    <td>
                                        <button type="button" @click.prevent="agregarDetalleModal(producto)" class="btn btn-success btn-sm">
                                        <i class="fas fa-check-circle"></i>
                                        </button>
                                    </td>

                                   <td v-text="producto.codigo"></td>
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.nombre"></td>
                                    <td v-else v-text="producto.nombre"></td>
                                     <td v-text="producto.descripcion"></td>
                                    
                                    <td v-text="producto.precio_venta"></td>
                                    
                                    <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                                     <td v-else v-text="producto.stock"></td>
                                    <td>
                                        <div v-if="producto.condicion">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                        <div v-else>
                                            <span class="badge badge-danger">Desactivado</span>
                                        </div>

                                    </td>

                                </tr>

                            </tbody>
                        </table>
                        <nav>
                        <ul class="pagination pagination-sm m-0 float-right">
                        <li class="page-item" v-if="pagination2.current_page > 1">
                            <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page - 1,buscarA,criterioA)" >&laquo;</a>
                        </li>
                        <li class="page-item" v-for="page in pagesNumber2" :key="page" :class="[page == isActived2 ? 'active' : '']">
                            <a class="page-link" href="#" @click="cambiarPagina2(page,buscarA,criterioA)" v-text="page"></a>
                            </li>
                        <li class="page-item" v-if="pagination2.current_page < pagination2.last_page">
                            <a class="page-link" href="#" @click="cambiarPagina2(pagination2.current_page + 1,buscarA,criterioA)">&raquo;</a>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cerrarModal()">Cerrar</button>

                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!--Fin del modal-->




    <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modal2}" >
<div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header bg-primary">
      <h4 class="modal-title" v-text="tituloModal2"></h4>
      <button type="button" class="close" @click="cerrarModal()" >
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body">

           <div class="card-body table-responsive">
        <table  class="table table-bordered table-striped">
          <thead>
          <tr>


            <th>Nombre</th>
            <th>Stock</th>
            <th>Descripcion</th>
            <th>Minimo</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="producto in arrayProducto" :key="producto.id" >


                            <td v-if="producto.stock <= producto.minimo"  v-text="producto.nombre"></td>
                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.stock"></td>
                            <td v-if="producto.stock <= producto.minimo" v-text="producto.descripcion"></td>
                            <td v-if="producto.stock <= producto.minimo" class="bg-danger" v-text="producto.minimo"></td>




          </tr>


          </tbody>
          <tfoot>

          </tfoot>
        </table>
        <div class="card-footer clearfix">
         <nav>
        <ul class="pagination pagination-sm m-0 float-right">
          <li class="page-item" v-if="pagination.current_page > 1">
            <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page - 1,buscar,criterio)" >&laquo;</a>
         </li>
          <li class="page-item" v-for="page in pagesNumber" :key="page" :class="[page == isActived ? 'active' : '']">
            <a class="page-link" href="#" @click="cambiarPagina(page,buscar,criterio)" v-text="page"></a>
            </li>
          <li class="page-item" v-if="pagination.current_page < pagination.last_page">
            <a class="page-link" href="#" @click="cambiarPagina(pagination.current_page + 1,buscar,criterio)">&raquo;</a>
            </li>
        </ul>
        </nav>
      </div>
    </div>

    </div>
    <div class="modal-footer ">
      <button type="button" class="btn btn-default"  @click="cerrarModal()">Cerrar</button>
    </div>
  </div>
  <!-- /.modal-content -->
</div>
<!-- /.modal-dialog -->
</div>
<!-- /.modal -->




     <!------------------------------------------------- Modal Cliente ------------------------------>

    <div class="modal fade p-5" tabindex="-1" :class="{'mostrar' : modalB}" role="dialog" aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-primary modal-lg modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" v-text="tituloModalB"></h4>
                    <button type="button" class="close" @click="cerrarModalB()" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-md-6">
                            <div class="input-group">
                                <select class="form-control col-md-3" v-model="criterioB">
                                <option value="nit">Nit</option>
                                <option value="nombre">Nombre</option>

                                </select>
                                <input type="text" v-model="buscarB" @keyup.enter="listarCliente(buscarB,criterioB)" class="form-control" placeholder="Texto a buscar">
                                <button type="submit" @click="listarCliente(buscarB,criterioB)" class="btn btn-primary"><i class="fa fa-search"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table  class="table table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>Opciones</th>
                                    <th>Nit</th>
                                    <th>Nombre</th>
                                    <th>Direccion</th>
                                    <th>Telefono</th>
                                     <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cliente in arrayCliente" :key="cliente.id">
                                    <td>
                                        <button type="button" @click.prevent="agregarClienteModal(cliente)" class="btn btn-success btn-sm">
                                        <i class="fas fa-check-circle"></i>
                                        </button>
                                    </td>

                                    <td v-text="cliente.nit"></td>
                                    <td v-text="cliente.nombre"></td>
                                    <td v-text="cliente.direccion"></td>
                                    <td v-text="cliente.telefono"></td>
                                    <td>
                                        <div v-if="cliente.condicion">
                                            <span class="badge badge-success">Activo</span>
                                        </div>
                                        <div v-else>
                                            <span class="badge badge-danger">Desactivado</span>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cerrarModalB()">Cerrar</button>
                    <button type="button" v-if="tipoAccion==1" class="btn btn-primary" @click.prevent="registrarCliente()">Guardar</button>
                    <button type="button" v-if="tipoAccion==2" class="btn btn-primary" @click.prevent="actualizarCliente()">Actualizar</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!--Fin del modal-->

    <div tabindex="-1" class="modal fade p-5" :class="{'mostrar' : modalC}" >
<div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header bg-primary">
      <h4 class="modal-title" v-text="tituloModalC"></h4>
      <button type="button" class="close" @click.prevent="cerrarModalC()" >
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
          <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">

         <div class="form-group row">
            <label for="recipient-name" class="col-form-label">Nit:</label>
             <input type="text" class="form-control" @blur="duplicado()"   v-model="nit">
          </div>

          <div class="form-group row">
             <label for="message-text" class="col-form-label">Nombre:</label>
              <input type="text" class="form-control"  v-model="nombre" >
          </div>

          <div class="form-group row">
             <label for="message-text" class="col-form-label">Direccion:</label>
              <input type="text" class="form-control"  v-model="direccion" >
          </div>

          <div class="form-group row">
             <label for="message-text" class="col-form-label">Telefono:</label>
              <input type="text" class="form-control"  v-model="telefono" >
          </div>


           <div v-show="errorCliente" class="form-growp row  div-error">
              <div class="text-center text-error">
                                <div v-for="error in errorMostrarMsjCliente" :key="error" v-text="error">

                                </div>
                            </div>
          </div>
          <div v-show="deshabilitar_boton" class="form-growp row  div-error">
            <p class="text-center text-error">El Nit ya Existe!</p>
          </div>
          </form>
        </div>
    </div>
    <div class="modal-footer ">
      <button type="button" class="btn btn-default"  @click.prevent="cerrarModalC()">Cerrar</button>
      <button type="button" :disabled = "deshabilitar_boton==1" class="btn btn-primary" @click="registrarCliente()">Guardar</button>

    </div>
  </div>
  <!-- /.modal-content -->
</div>
<!-- /.modal-dialog -->
</div>
<!-- /.modal -->


</main>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
data (){
    return {
        tracking_id: 0,
        idcliente:0,
        idlugar:[],
        idlugar2:1,
        nombrelugar: '',
        direccionlugar: '',

        nit: '',
        lugar:'',
        
        cliente:'',
        codigo:'',
        nombre : '',
        nombrep : '',
        direccion : '',
        telefono : '',
        bcliente : '',
        tipo_comprobante : 'COD',
        num_comprobante : '',
        datab: '',
        estado : '',
        urlhttp: this.$api_url,
        envio: '',
        totalEnvio: 0.0,
        deshabilitar_boton:0,
        impuesto: 0.0,
        totalG:0.0,
        totalN:0.0,
        total:0.0,
        totalc:0.0,
        totalu:0.0,


        totalImpuesto: 0.0,
        totalParcial: 0.0,
        arrayTracking : [],
        arrayCliente: [],
        arrayDetalle : [],
        arrayLugar : [],
         arrayLugar2 : [],
         arrayAnularV: [],
        listado:1,
        modal : 0,
        modalB : 0,
        modalC : 0,
         modal2 : 0,
        tituloModal : '',
        tituloModalB : '',
        tituloModalC : '',
         tituloModal2 : '',
        tipoAccion : 0,
        errorTracking : 0,
         errorCliente : 0,
        errorMostrarMsjTracking : [],
        errorMostrarMsjCliente : [],
         arrayBuscarCliente: [],
          ntotal :0.0,
        'mensaje':'',
        pagination : {
            'total' : 0,
            'current_page' : 0,
            'per_page' : 0,
            'last_page' : 0,
            'from' : 0,
            'to' : 0,
        },
        offset : 3,

         pagination2 : {
            'total' : 0,
            'current_page' : 0,
            'per_page' : 0,
            'last_page' : 0,
            'from' : 0,
            'to' : 0,
        },
        offset2 : 3,
        criterio : 'fecha_hora',
        buscar : '',
        criterioA:'nombre',
        buscarA: '',
        criterioB:'nombre',
        buscarB: '',
        arrayProducto: [],
        idproducto: 0,
       // codigo: '',
        producto: '',
        precio: 0,
        cantidad:0,
        descuento: 0,
        cambio: 0,
        negativo: 0,
        stock: 0
    }
},
components: {

},
computed:{
    isActived: function(){
        return this.pagination.current_page;
    },
    isActived2: function(){
        return this.pagination2.current_page;
    },
    //Calcula los elementos de la paginación
    pagesNumber: function() {
        if(!this.pagination.to) {
            return [];
        }

        var from = this.pagination.current_page - this.offset;
        if(from < 1) {
            from = 1;
        }

        var to = from + (this.offset * 2);
        if(to >= this.pagination.last_page){
            to = this.pagination.last_page;
        }

        var pagesArray = [];
        while(from <= to) {
            pagesArray.push(from);
            from++;
        }
        return pagesArray;
    },
    pagesNumber2: function() {
        if(!this.pagination2.to) {
            return [];
        }

        var from = this.pagination2.current_page - this.offset2;
        if(from < 1) {
            from = 1;
        }

        var to = from + (this.offset2 * 2);
        if(to >= this.pagination2.last_page){
            to = this.pagination2.last_page;
        }

        var pagesArray2 = [];
        while(from <= to) {
            pagesArray2.push(from);
            from++;
        }
        return pagesArray2;
    },

    totalDescuento: function(){
        var resultado=0.0;

        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado+=parseFloat( this.arrayDetalle[i].descuento)
        }
        return resultado;

            },

              calcularTotalN: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precionormal*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalv: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayVenta.length;i++){

                     if(this.arrayVenta[i].estado=="Registrado"){
                       resultado +=parseFloat(this.arrayVenta[i].total)
                     }

                }
                return resultado.toFixed(2);
            },
            contarVenta: function(){

               var resultado=0.0;
                for(var i=0;i<this.arrayVenta.length;i++){

                    resultado =i+1;

                }
                return resultado;
            },
            calcularTotal: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalc: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad)
                }
                return resultado.toFixed(2);
            },


            calcularTotalu: function(){
                var resultado=0.0;
                for(var i=0;i<this.arrayDetalle.length;i++){

                    resultado=resultado+((this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)-(this.arrayDetalle[i].costo*this.arrayDetalle[i].cantidad))
                }
                return resultado.toFixed(2);
            },

     totalMasEnvio: function(){

        var resultado=0.0;
        var resultado2=0.0;

        for(var i=0;i<this.arrayDetalle.length;i++){

            resultado=resultado+(this.arrayDetalle[i].precio*this.arrayDetalle[i].cantidad)
          }

          resultado2 = parseFloat(resultado + this.envio).toFixed(2);

        return resultado2;

    },


    totalArticulo: function(){
        var result=0;
         for(var i=0;i<this.arrayDetalle.length;i++){

            result+=(this.arrayDetalle[i].cantidad)
        }
        return result;


    },

   

    contarAlerta: function(){

      var resultado=0;
        for(var i=0;i<this.arrayProducto.length;i++){

            if(this.arrayProducto[i].stock <= this.arrayProducto[i].minimo){

              resultado +=1;
            }


        }
        return resultado;
    }
},
methods : {


/**************************************************************************************** */
    listarTracking: async function (page,buscar,criterio){

        let me=this;
        var url= '/trackingv?page=' + page + '&buscar='+ buscar + '&criterio='+ criterio;
       await axios.get(url).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayTracking = respuesta.trackings.data;
            me.pagination= respuesta.pagination;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

/**************************************************************************************** */
    buscarCliente(){
        let me=this;


        var url= '/cliente/selectCliente?filtro='+search;
        axios.get(url).then(function (response) {
            let respuesta = response.data;
            me.arrayCliente=respuesta.clientees;

        })
        .catch(function (error) {
            console.log(error);
        });
    },

/**************************************************************************************** */
    getDatosCliente(val1){
        let me = this;
        me.loading = true;
        me.idcliente = val1.id;
    },

 /**************************************************************************************** */
    buscarProducto(){
        let me=this;
        var url= '/existencia/buscarProducto?filtro=' + me.nombre;

        axios.get(url).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos;

            if (me.arrayProducto.length>0){
                me.producto=me.arrayProducto[0]['nombre'];
                me.idproducto=me.arrayProducto[0]['id'];
            }
            else{
                me.producto='No existe producto';
                me.idproducto=0;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },

    buscarProducto2(){

       let me=this;
       var url= '/existencia/buscarProducto2T?filtro=' + me.codigo;



        axios.get(url).then(function (response) {

            //console.log(response);
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos;





            if (me.arrayProducto.length>0){
                me.codigo=me.arrayProducto[0]['codigo'];
                me.idproducto=me.arrayProducto[0]['id'];
                me.nombre=me.arrayProducto[0]['nombre'];
                me.costo=me.arrayProducto[0]['precio_costo'];
                me.precio=me.arrayProducto[0]['precio_venta'];
                me.precionormal= me.arrayProducto[0]['precio_venta'],

                            me.precio01= me.arrayProducto[0]['precio01'],
                            me.precio0= me.arrayProducto[0]['precio0'],
                            me.precio1= me.arrayProducto[0]['precio1'],
                            me.precio2= me.arrayProducto[0]['precio2'],
                            me.precio3= me.arrayProducto[0]['precio3'],
                            me.precio4= me.arrayProducto[0]['precio4'],
                me.stock= me.arrayProducto[0]['stock'];


         if(me.encuentra(me.arrayProducto[0]['id']) ){
             /*
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
                    */


                 //

                        for(var i=0;i<me.arrayDetalle.length;i++){
            if(me.arrayDetalle[i].idproducto==me.idproducto){
                me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
            }
        }

            }else{
                me.arrayDetalle.push({
                    idproducto: me.arrayProducto[0]['id'],
                    codigo: me.arrayProducto[0]['codigo'],
                    producto: me.arrayProducto[0]['nombre'],
                    cantidad: 1,
                    costo: me.arrayProducto[0]['precio_costo'],
                    precio: me.arrayProducto[0]['precio_venta'],
                    descuento: 0,
                             precionormal: me.arrayProducto[0]['precio_venta'],

                            precio01: me.arrayProducto[0]['precio01'],
                            precio0: me.arrayProducto[0]['precio0'],
                            precio1: me.arrayProducto[0]['precio1'],
                            precio2: me.arrayProducto[0]['precio2'],
                            precio3: me.arrayProducto[0]['precio3'],
                            precio4: me.arrayProducto[0]['precio4'],
                            stock: me.arrayProducto[0]['stock'],
                });
            }


/*
                 if(me.arrayDetalle[i].idproducto==me.arrayProducto[0]['id']){

                     me.arrayDetalle.push({
                         cantidad: cantidad+1
                         });

                 }else{

                     me.arrayDetalle.push({
                    idproducto: me.arrayProducto[0]['id'],
                    codigo: me.arrayProducto[0]['codigo'],
                    producto: me.arrayProducto[0]['nombre'],
                    cantidad: 1,
                    costo: me.arrayProducto[0]['precio_costo'],
                    precio: me.arrayProducto[0]['precio_tracking'],
                    descuento: 0,
                    stock: me.arrayProducto[0]['stock'],
                });

                 }

            */


                /*
                 me.arrayDetalle.push({
                    idproducto: data['id'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_tracking'],
                    costo: data['precio_costo'],
                    descuento:0,
                    stock:data['stock']

                });
                */
               /*
                console.log(me.idproducto);
                console.log(me.codigo);
               console.log(me.nombre);
               console.log(me.costo);
               console.log(me.precio);
               console.log(me.stock);
               */
               me.codigo="";
            }
            else{


                   me.arrayProducto = [];

                 me.codigo = "";
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Error de codigo Revise el Stock de esta Tienda!',
                    })


            }
        })
        .catch(function (error) {
            console.log(error);
        });

    },


             buscarBcliente(){
let me=this;

      if(me.bcliente==""){

          me.bcliente;
                me.arrayCliente=[];

          swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ingrese un Numero de Nit!',
                    })
              me.arrayCliente=[];
                me.idcliente="";
               me.nit="";
                me.nombrep="";
                me.direccion="";
                me.telefono="";

      }else{

          var url= '/cliente/bcliente?filtro=' + me.bcliente;



        axios.get(url).then(function (response) {
        /*
          var respuesta= response.data;
            me.arrayProducto = respuesta.productos;
            */
            var respuesta= response.data;
            me.arrayBuscarCliente = respuesta.buscaCliente;
           /*
              var respuesta= response.data;
              me.arrayCliente = respuesta.cliente.data;
           */



            if(me.arrayBuscarCliente.length>0){



               me.idcliente=me.arrayBuscarCliente[0]["id"];
               me.nit=me.arrayBuscarCliente[0]["nit"];
                me.nombrep=me.arrayBuscarCliente[0]["nombre"];
                me.direccion=me.arrayBuscarCliente[0]["direccion"];
                me.telefono=me.arrayBuscarCliente[0]["telefono"];


               /*
                me.nit=me.arrayBuscarCliente[1];
                me.nombre=me.arrayBuscarCliente[2];
                me.direccion=me.arrayBuscarCliente[3];
                me.telefono=me.arrayBuscarCliente[4];
                */


            }

            else{

                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'No existe el Nit!',
                    })

                me.bcliente="";
                me.arrayBuscarCliente=[];

                me.idcliente="";
               me.nit="";
                me.nombrep="";
                me.direccion="";
                me.telefono="";



            }



        })
        .catch(function (error) {
            console.log(error);
        });

      }





},




     selectLugar(){
        let me=this;
        var url= '/lugar/selectLugar';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayLugar = respuesta.lugares;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

    selectLugar2(){
        let me=this;
        var url= '/lugar/selectLugar';
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayLugar2 = respuesta.lugares;
        })
        .catch(function (error) {
            console.log(error);
        });
    },




  /**************************************************************************************** */

    cambiarPagina(page,buscar,criterio){
        let me = this;
        //Actualiza la página actual
        me.pagination.current_page = page;
        //Envia la petición para visualizar la data de esa página
        me.listarTracking(page,buscar,criterio);
    },

     cambiarPagina2(page,buscarA,criterioA){
        let me = this;
        //Actualiza la página actual
        me.pagination2.current_page = page;
        //Envia la petición para visualizar la data de esa página
        me.listarProducto(page,buscarA,criterioA);
    },

  /**************************************************************************************** */
    encuentra(id){
        var sw=0;
        for(var i=0;i<this.arrayDetalle.length;i++){
            if(this.arrayDetalle[i].idproducto==id){
                sw=true;
            }
        }
        return sw;
    },

  /**************************************************************************************** */
    eliminarDetalle(index){
        let me = this;
        me.arrayDetalle.splice(index, 1);
    },


   /**************************************************************************************** */
    agregarDetalle(){
        let me=this;
        if(me.idproducto==0 || me.cantidad==0 || me.precio==0){
        }
        else{
            if(me.encuentra(me.idproducto)){
                /*
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
                    */


                 for(var i=0;i<this.arrayDetalle.length;i++){
            if(me.arrayDetalle[i].idproducto==id){
                me.arrayDetalle[i].cantidad++
            }
        }
            }
            else{
               me.arrayDetalle.push({
                    idproducto: me.idproducto,
                    codigo: me.codigo,
                    producto: me.producto,
                    cantidad: me.cantidad,
                    precio: me.precio,
                    costo: me.costo,
                    precionormal: me.precio,

                            precio01: me.precio01,
                            precio0: me.precio0,
                            precio1: me.precio1,
                            precio2: me.precio2,
                            precio3: me.precio3,
                            precio4: me.precio4,
                            stock: me.stock
                });
                me.codigo="";
                me.idproducto=0;
                me.producto="";
                me.cantidad=0;
                me.precio=0;
                me.costo=0;
                me.precionormal=0;

                        me.precio01=0;
                        me.precio0=0;
                        me.precio1=0;
                        me.precio2=0;
                        me.precio3=0;
                        me.precio4=0;
                        me.stock=0
            }

        }



    },

     /**************************************************************************************** */
    agregarDetalleModal(data =[]){
       

        let me=this;
        if(me.encuentra(data['id'])){
            /*
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
                    */
                                   for(var i=0;i<me.arrayDetalle.length;i++){
            if(me.arrayDetalle[i].idproducto==data['id']){
                me.arrayDetalle[i].cantidad= me.arrayDetalle[i].cantidad + 1;
            }
        }


            }
            else{
               me.arrayDetalle.push({
                    idproducto: data['id'],
                     codigo: data['codigo'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_venta'],
                    costo: data['precio_costo'],
                    precionormal: data['precio_venta'],
                            descuento:0,
                            precio01: data['precio01'],
                            precio0: data['precio0'],
                            precio1: data['precio1'],
                            precio2: data['precio2'],
                            precio3: data['precio3'],
                            precio4: data['precio4'],
                            stock:data['stock']

                });
            }
    },

    agregarDetalleModal2(data =[]){
        

        let me=this;
        if(me.encuentra(data['id'])){
                swal.fire({
                    type: 'error',
                    title: 'Error...',
                    text: 'Ese producto ya se encuentra agregado!',
                    })
            }
            else{
               me.arrayDetalle.push({
                    idproducto: data['id'],
                    codigo: data['codigo'],
                    producto: data['nombre'],
                    cantidad: 1,
                    precio: data['precio_venta'],
                    costo: data['precio_costo'],
                    descuento:0,
                    stock:data['stock']

                });
            }
    },

    /**************************************************************************************** */
    agregarClienteModal(data =[]){


        let me=this;


                        me.idcliente=data['id'];
                        me.nit = data['nit'];
                        me.nombrep = data['nombre'];
                        me.direccion = data['direccion'];
                        me.telefono = data['telefono'];
        this.cerrarModalB();
        this.bcliente = "";

    },

    registrarCliente(){
        if (this.validarCliente()){
            return;
        }

        let me = this;
         me.deshabilitar_boton=1;
        axios.post('/cliente/registrar',{


            'nit': this.nit,
            'nombre': this.nombre,
            'direccion': this.direccion,
            'telefono' : this.telefono

        }).then(function (response) {

             me.bcliente ="";
            me.bcliente = me.nit;

            me.cerrarModalC();
            me.deshabilitar_boton=0;

             Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Datos Agregados Con Exito!',
                  showConfirmButton: false,
                  timer: 1500
                });
        }).catch(function (error) {
            console.log(error);
        });
    },
     /**************************************************************************************** */
    listarProducto (page,buscarA,criterioA){
        let me=this;
        var url= '/existenciav/listarProducto?page=' + page + '&buscar='+ buscarA + '&criterio='+ criterioA;
        axios.get(url).then(function (response) {
           // console.log(response);
            var respuesta= response.data;
            me.arrayProducto = respuesta.productos.data;
            me.pagination2= respuesta.pagination2;
        })
        .catch(function (error) {
            console.log(error);
        });
    },


    pdfTracking(id,num_comprobante){

        window.open('/tracking/pdf/'+id+','+'_blank');
/*
         axios({
                    url: this.urlhttp+'/tracking/pdf/'+id+','+'_blank',
                    method: 'GET',
                    responseType: 'blob', // important
                    }).then((response) => {
                        console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Tracking'+num_comprobante+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                    });
*/
    },







         /**************************************************************************************** */
    listarCliente (buscarB,criterioB){
         let me=this;
        var url= '/cliente/listarCliente?buscarB='+ buscarB + '&criterioB='+ criterioB;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            me.arrayCliente = respuesta.cliente.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },
     /**************************************************************************************** */




    registrarTracking: async function(){


/*
            console.log(this.idlugar);
          console.log(this.idlugar[0]);
          console.log(this.idlugar[1]);
*/

       // validarUser();


            if (this.validarTracking()){
            return;
        }

       
         
        //console.log(this.idlugar);
        /*
        console.log(this.ntotal);
        console.log(this.total);

        console.log(this.totalG);
       console.log(this.totalN);
        console.log(this.totalc);
       console.log(this.totalu);
       */
        // console.log(this.recibido);

        //let databan = 0;

        let me = this;
        me.deshabilitar_boton=1;

        me.totalc = me.calcularTotalc;
                me.totalu = me.calcularTotalu;

       if(this.tipo_comprobante=="COD"){
            this.ntotal=(this.totalG-this.envio).toFixed(2);
        }
    
        else if(this.tipo_comprobante=="COLLET"){
            this.ntotal=this.totalG;
        }

       
        

       await axios.post('/trackingv/registrar',{
            'idcliente': this.idcliente,
            'tipo_comprobante': this.tipo_comprobante,
            'num_comprobante' : this.num_comprobante,
           
            'envio' : this.envio,
           // 'databan': databan,

            
            'nombrelugar': this.nombrelugar,
            'direccionlugar':this.direccionlugar,

            //'serie_comprobante' : this.serie_comprobante,
            //'num_comprobante' : this.num_comprobante,
            //'impuesto' : this.impuesto,
            'totalc' : me.totalc,
            'totalu' : me.totalu,
            'total' : this.totalG,
            'total_neto' : this.totalN,
            'data': this.arrayDetalle

        }).then(function (response) {
          //console.log(response);

            me.listado=1;
            me.listarTracking(1,'','fecha_hora');

            me.idcliente=0;

            me.tipo_comprobante='COD';
            //me.serie_comprobante='';
            //me.num_comprobante='';
            //me.impuesto=0.18;
            me.totalG=0.0;

            me.totalN=0.0;
            me.total=0.0;
            me.totalc=0.0;
            me.totalu=0.0;
            me.envio=0;
            me.ntotal=0.0;
            me.idproducto=0;
            me.producto='';
            me.cantidad=0;
            me.costo=0;
            me.precio=0;
            me.descuento=0;

            me.arrayDetalle=[];
            me.arrayCliente=[];








            Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Datos Agregados Con Exito!',
                  showConfirmButton: false,
                  timer: 1000

                });

                setTimeout(function(){
                    window.open('/tracking/pdf/'+response.data.id+','+'_blank');
                }, 500);
                

/*
                   window.open('/tracking/pdf/'+response.data.id+','+'_blank');

                    axios({
                    url: me.urlhttp+'/tracking/pdf/'+response.data.id+','+'_blank',
                    method: 'GET',
                    responseType: 'blob', // important
                    }).then((response) => {
                        console.log(response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Tracking'+response.data.num_comprobante+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                    });


                }, 500);
*/
                  me.cerrarModal();
             me.cerrarModalC();

           me.deshabilitar_boton=0;

        }).catch(function (error) {
            console.log(error);
        });


    },

     /**************************************************************************************** */

    validarUser(){

       // window.location.href = '/logout';

        axios.post('/user/activado',{


        }).then(function (response) {

            console.log(response);
          if(response){

          }
          else{

                window.location.href = 'https://127.0.0.1:8000/logout';



          }




        }).catch(function (error) {
            console.log(error);
        });

    },
    


    validarTracking(){
        this.errorTracking=0;
        this.errorMostrarMsjTracking =[];


      



        if (this.idcliente==0) this.errorMostrarMsjTracking.push("Seleccione un Cliente");
        if (!this.nombrep) this.errorMostrarMsjTracking.push("El nombre es necesario");
        if (!this.direccion) this.errorMostrarMsjTracking.push("La Direccion es Necesaria");
        if (!this.telefono) this.errorMostrarMsjTracking.push("El Telefono es Necesario");
       

        if (this.tipo_comprobante==0) this.errorMostrarMsjTracking.push("Seleccione el comprobante");
        if (!this.num_comprobante) this.errorMostrarMsjTracking.push("Ingrese el número de comprobante");
        if (!this.envio) this.errorMostrarMsjTracking.push("Ingrese el envio de la guia");
        if (this.arrayDetalle.length<=0) this.errorMostrarMsjTracking.push("Ingrese detalles");

              if (this.tipo_comprobante=='SE') {
                  if (!this.datab) this.errorMostrarMsjTracking.push("Los datos del deposito o trasaccion son nesesarios");
              }


        if (this.errorMostrarMsjTracking.length) this.errorTracking = 1;

        return this.errorTracking;
    },



    validarCliente(){


       this.errorCliente=0;
        this.errorMostrarMsjCliente  =[];


        if (!this.nit){
          this.errorMostrarMsjCliente .push("El nit no puede estar vacío.");

        }
        if (!this.nombre){
           this.errorMostrarMsjCliente.push("El nombre no puede estar vacio.");

        }
        /*
        if (!this.direccion){
          this.errorMostrarMsjCliente.push("La Direccion no puede estar vacío.");

        }

        if (!this.telefono){
          this.errorMostrarMsjCliente.push("El telefono no puede estar vacío.");

        }
        */


        if (this.errorMostrarMsjCliente.length) this.errorCliente = 1;

        return this.errorCliente;

    },


     /**************************************************************************************** */
    mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.nit='';
        me.nombre='';
        me.direccion='';
        me.telefono='';
        me.tipo_comprobante='COD';
        me.serie_comprobante='';
        me.num_comprobante='';
        me.envio='';
        //me.impuesto=0.18;
        me.total=0.0;
        me.totalc=0.0;
        me.totalu=0.0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.arrayDetalle=[];
    },

     /**************************************************************************************** */
    ocultarDetalle(){
        this.listado=1;
    },

     /**************************************************************************************** */
    verTracking(id){
        let me=this;
        me.listado=2;

        //Obtener los datos del tracking
        var arrayTrackingT=[];
        var url= '/trackingv/obtenerCabecera?id=' + id;

        axios.get(url).then(function (response) {
            var respuesta= response.data;
            arrayTrackingT = respuesta.tracking;

            me.cliente = arrayTrackingT[0]['nombre'];
            me.tipo_comprobante=arrayTrackingT[0]['tipo_comprobante'];

            me.envio=arrayTrackingT[0]['envio'];

            me.num_comprobante=arrayTrackingT[0]['num_comprobante'];
            me.estado=arrayTrackingT[0]['estado'];
            //me.impuesto=arrayTrackingT[0]['impuesto'];

            me.total=arrayTrackingT[0]['total'];
            me.totalc=arrayTrackingT[0]['totalc'];
            me.totalu=arrayTrackingT[0]['totalu'];

        })
        .catch(function (error) {
            console.log(error);
        });

        //Obtener los datos de los detalles
        var urld= '/trackingv/obtenerDetalles?id=' + id;

        axios.get(urld).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayDetalle = respuesta.detalles;
        })
        .catch(function (error) {
            console.log(error);
        });
    },

     /**************************************************************************************** */
    cerrarModal(){
        this.modal=0;
        this.tituloModal='';
        this.modal2=0;

        this.desbloquear=0;
        this.deshabilitar_boton=0;




        this.arrayBuscarCliente=[];
        this.bcliente="";


    },

     /**************************************************************************************** */
    abrirModal(){
        this.arrayProducto=[];
        this.modal = 1;
        this.tituloModal = 'Seleccione uno o varios productos';
    },


     abrirModalAlarma(modelo2, accion, data = []){
      switch(modelo2){
            case "producto":
            {
              switch(accion){
                case 'alarma':
                    {
                      this.modal2=1;
                      this.tituloModal2='Stock Minimo';
                      break;
                    }
              }
            }
      }

    },

     /**************************************************************************************** */

    desactivarTracking(id,num_comprobante,total){

         let me = this;
         //let lugar2 = this.idlugar2;


         var urld= '/trackingv/obtenerDetalles?id=' + id +'&num_comprobante='+num_comprobante;

        axios.get(urld).then(function (response) {
            //console.log(response);
            var respuesta= response.data;
            me.arrayAnularV = respuesta.detalles;



        })
        .catch(function (error) {
            console.log(error);
        });

     this.detallelist(id,lugar2,num_comprobante,total);


        },


 /**************************************************************************************** */
   detallelist(id,lugar2,num_comprobante,total){

/*
       Swal.fire({
        title: 'Esta seguro/a que desea Anular esta Guia?',

        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {


        if (result.value) {
          let me = this;

          console.log(total);
              console.log(num_comprobante);
               console.log(lugar2);
                console.log(id);
                console.log(this.arrayAnularV);

        axios.put('/venta/desactivar',{
                  'id': id,
                  'num_comprobante':num_comprobante,
                  'total':total,
                  'lugar2': lugar2,
                  'data': this.arrayAnularV


                }).then(function(response){
                  //  console.log(response);
                  me.listarVenta(1,'','fecha_hora',lugar2);
                  me.arrayAnularV=[];
                  Swal.fire(
                    'Anulado!',
                    'El registro a sido desactivado con exito.',
                    'success'
                  )
                }).catch(function(error){
                  console.log(error);
                });
         // console.log(this.arrayAnularV);
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      })
*/

       Swal.fire({
            title: 'Razon de Anulacion:',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            showLoaderOnConfirm: true,
            preConfirm: (anular) => {

/*
                  if (this.validarVentap()){
                           return;
                     }
*/
                     if(!anular){

                     }else{



          let me = this;
          /*
          console.log(total);
              console.log(num_comprobante);
               console.log(lugar2);
                console.log(id);
                console.log(this.arrayAnularV);
             */

             
        axios.put('/trackingv/desactivar',{
                  'id': id,
                  'num_comprobante':num_comprobante,
                  'total':total,
                  'anular': anular,
                  'lugar2': lugar2,
                  'data': this.arrayAnularV


                }).then(function(response){
                  // console.log(response);
                  me.listarTracking(1,'','fecha_hora',lugar2);
                  me.arrayAnularV=[];


                      Swal.fire({
                  position: 'top',
                  icon: 'success',
                  title: 'Anulacion Exitosa!',
                  showConfirmButton: false,
                  timer: 1500

                });

                }).catch(function(error){
                  console.log(error);
                });


                     }

            },
            allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: `${result.value.login}'s avatar`,
                imageUrl: result.value.avatar_url
                })
            }
            })

   },


   /*
   desactivarTracking : function(id){
      Swal.fire({
        title: 'Esta seguro/a que desea Anular este Tracking?',

        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          let me = this;
          var arrayTrackingT=[];
                axios.put('/tracking/desactivar',{
                  'id': id


                }).then(function(response){
                  me.listarTracking(1,'','tipo_comprobante');
                  Swal.fire(
                    'Anulado!',
                    'El registro a sido desactivado con exito.',
                    'success'
                  )
                }).catch(function(error){
                  console.log(error);
                });


        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      })
        },
*/
 /**************************************************************************************** */




 /**************************************************************************************** */

entregadaTracking : function(id){
      Swal.fire({
        title: 'Esta seguro/a que el paquete fue Entregado?',

        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          let me = this;
          var arrayTrackingT=[];
                axios.put('/trackingv/entregada',{
                  'id': id

                }).then(function(response){
                  me.listarTracking(1,'','tipo_comprobante');
                  Swal.fire(
                    'Paquete Entregado!',
                    'El registro a sido desactivado con exito.',
                    'success'
                  )
                }).catch(function(error){
                  console.log(error);
                });


        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      })
        },


         cerrarModalB(){
        this.modalB=0;
        this.tituloModalB='';
    },
    abrirModalB(){
        this.arrayCliente=[];
        this.modalB = 1;
        this.tituloModalB = 'Seleccione el cliente';
    },

     cerrarModalC(){
        this.modalC=0;
        this.tituloModalC='';

        this.nit='';
        this.nombrep='';
        this.direccion='';
        this.telefono='';
        this.errorCliente=0;
        this.val1=0;
        this.val2=0;
        this.val3=0;
        this.val4=0;
        this.deshabilitar_boton=0;
    },
    abrirModalC(){

        this.nit='';
        this.nombre='';
        this.direccion='';
        this.telefono='';

        this.modalC = 1;
        this.tituloModalC = 'Registrar Cliente';

    },

     duplicado(){

       axios.put('/cliente/validar',{

            'nit': this.nit,
            'nombre': this.nombre,


        }).then(response => {
          this.mensaje = response.data;
          if(this.mensaje==="Existe"){
              this.deshabilitar_boton=1;

          }else{
            this.deshabilitar_boton=0;
          }
        })
        .catch(error => {
           console.log(err);
         });

    },
/*
     mostrarDetalle(){
        let me=this;
        me.listado=0;

        me.idcliente=0;
        me.nombre='';
        me.direccion='';
        me.telefono='';
        me.tipo_comprobante='COD';
        me.serie_comprobante='';
        me.num_comprobante='';
        //me.impuesto=0.18;
        me.total=0.0;
        me.totalc=0.0;
        me.totalu=0.0;
        me.idproducto=0;
        me.producto='';
        me.cantidad=0;
        me.precio=0;
        me.costo=0;
        me.arrayDetalle=[];
    },
    */
    ocultarDetalle(){
        this.listado=1;
        this.cerrarModal();
    },

},
mounted() {
    this.selectLugar2();
    this.listarTracking(1,this.buscar,this.criterio);
    this.selectLugar();

}
}
</script>
<style>
.modal-content{
width: 100% !important;
position: absolute !important;
}
.mostrar{
display: list-item !important;
opacity: 1 !important;
position: absolute !important;
background-color: #3c29297a !important;
}
.div-error{
display: flex;
justify-content: center;
}
.text-error{
color: red !important;
font-weight: bold;
}
@media (min-width: 600px) {
.btnagregar {
    margin-top: 2rem;
}
}

</style>
