require('./bootstrap');

import Vue from 'vue';


window.Vue = require('vue');

Vue.prototype.$axios = axios
Vue.prototype.$api_url = "https://elpueblitogestion.com"

Vue.component('tienda', require('./components/Tienda.vue').default);
Vue.component('rol', require('./components/Rol.vue').default);
Vue.component('user', require('./components/User.vue').default);

Vue.component('cliente', require('./components/Cliente.vue').default);
Vue.component('proveedor', require('./components/Proveedor.vue').default);
Vue.component('caja', require('./components/Caja.vue').default);
Vue.component('cajaadmin', require('./components/Cajaadmin.vue').default);

Vue.component('marca', require('./components/Marca.vue').default);
Vue.component('genero', require('./components/Genero.vue').default);
Vue.component('diseno', require('./components/Diseno.vue').default);
Vue.component('tela', require('./components/Tela.vue').default);
Vue.component('color', require('./components/Color.vue').default);
Vue.component('talla', require('./components/Talla.vue').default);
Vue.component('lote', require('./components/Lote.vue').default);

/** necesita pdf */
Vue.component('producto', require('./components/Producto.vue').default);
Vue.component('existencia', require('./components/Existencia.vue').default);

Vue.component('compra', require('./components/Compra.vue').default);
/** necesita pdf */
Vue.component('trasladosalida', require('./components/Trasladosalida.vue').default);
Vue.component('trasladoentrada', require('./components/Trasladoentrada.vue').default);
/** necesita pdf */
Vue.component('trasladosalidav', require('./components/Trasladosalidav.vue').default);
Vue.component('trasladoentradav', require('./components/Trasladoentradav.vue').default);

/*** necesita pdf */
Vue.component('venta', require('./components/Venta.vue').default);
Vue.component('ventap', require('./components/Ventap.vue').default);
Vue.component('ventapadmin', require('./components/VentaPadmin.vue').default);
Vue.component('ventaadmin', require('./components/Ventaadmin.vue').default);

Vue.component('reportealmacen', require('./components/ReporteAlmacen.vue').default);
Vue.component('reportecompra', require('./components/ReporteCompra.vue').default);
Vue.component('reporteventa', require('./components/ReporteVenta.vue').default);
Vue.component('reporteventap', require('./components/Reporteventap.vue').default);
Vue.component('reportecliente', require('./components/ReporteCliente.vue').default);
Vue.component('reporteproveedor', require('./components/ReporteProveedor.vue').default);
Vue.component('reportetopcompra', require('./components/Reportetopcompra.vue').default);
Vue.component('reportetopventa', require('./components/Reportetopventa.vue').default);

Vue.component('kardex', require('./components/Kardex.vue').default);
Vue.component('comprames', require('./components/CompraMes.vue').default);
Vue.component('ventames', require('./components/VentaMes.vue').default);
Vue.component('ventapmes', require('./components/VentapMes.vue').default);


/** necesita pdf */
Vue.component('salidainv', require('./components/Salidainv.vue').default);
Vue.component('entradainv', require('./components/Entradainv.vue').default);

/** necesita pdf */
Vue.component('comisiones', require('./components/Comisiones.vue').default);


Vue.component('giftcard', require('./components/Giftcard.vue').default);

Vue.component('puntos', require('./components/Puntos.vue').default);

Vue.component('kardexpunto', require('./components/Kardexpunto.vue').default);

Vue.component('kardexgift', require('./components/Kardexgift.vue').default);

Vue.component('Tracking', require('./components/Tracking.vue').default);

Vue.component('reporteguia', require('./components/ReporteGuia.vue').default);

Vue.component('Trackingv', require('./components/trackingv.vue').default);

Vue.component('comisiong', require('./components/ComisionesGuia.vue').default);




const app = new Vue({
    el: '#app',
    data:{
        menu: 0,
    }
});
